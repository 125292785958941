import {
  type VNode,
  createVNode,
  defineComponent,
  provide,
  ref,
  render,
} from 'vue'
import { tooltipAppContext, tooltipEmits } from './props'
import Main from './main.vue'
import type { ElTooltipProps } from './props'
type TooltipEmitsVNodeTypes = typeof tooltipEmits
type CamelCase<S extends string> = S extends `${infer P}-${infer Q}`
  ? `${CamelCase<P>}${CamelCase<Q>}`
  : Capitalize<S>

type UpperTooltipEmitsVNodeTypes = `on${CamelCase<
  TooltipEmitsVNodeTypes[number]
>}`

type PropsAndEventsType = ElTooltipProps &
  Record<typeof tooltipEmitsVNodeKeys[number], (...args: any[]) => any> & {
    closeUseTooltip: () => void
    __provides__: Record<string | symbol, unknown>
  }

const tooltipEmitsVNodeKeys = tooltipEmits.map((item) => {
  return `on${item[0].toUpperCase()}${item
    .slice(1)
    .replace(/-(\w)/g, (_, c) => c.toUpperCase())}`
}) as UpperTooltipEmitsVNodeTypes[]
export function renderTooltip(props: PropsAndEventsType, e: Element) {
  if (props.disabled) return
  if (Reflect.get(e, '__tooltip__')) {
    return
  }
  Reflect.set(e, '__tooltip__', 1)
  const domList = document.querySelectorAll('.group-tooltip-list-item')
  Array.from(domList).forEach((item) => item.remove())
  const container = document.createElement('div')
  container.className = 'group-tooltip-list-item'
  let vNode: VNode | null
  let destroyTooltip: (() => void) | null
  const tooltipRef = ref()
  const renderComponent = defineComponent({
    setup() {
      const isRender = ref(true)
      getAllKeys(props.__provides__).forEach((key) => {
        provide(key, props.__provides__[key])
      })
      destroyTooltip = () => {
        setTimeout(() => {
          isRender.value = false
          vNode = null
          render(null, container)
          container.remove()
          Reflect.set(e, '__tooltip__', null)
          tooltipRef.value = null
          props.closeUseTooltip?.()
        }, Math.max(0, props.hideAfter, props.showAfter) + 50)
      }

      return () => {
        if (!isRender.value) {
          return null
        }
        return createVNode(
          Main,
          {
            ...props,
            content: typeof props.content === 'string' ? props.content : '',
            virtualTriggering: true,
            virtualRef: e,
            ref: tooltipRef,
            onHide(value: any) {
              props.onHide?.(value)
              destroyTooltip?.()
            },
          },
          {
            content:
              typeof props.content !== 'string'
                ? (props.content as () => VNode)
                : undefined,
          }
        )
      }
    },
  })
  vNode = createVNode(renderComponent)
  vNode.appContext = tooltipAppContext._context
  render(vNode, container)
  document.body.appendChild(container)
  function unUseTooltip() {
    destroyTooltip?.()
    destroyTooltip = null
  }
  unUseTooltip.ref = tooltipRef.value
  return unUseTooltip
}

export function useTooltip(props: PropsAndEventsType, e: any) {
  const unUseTooltip = renderTooltip(props, e)
  if (props.trigger === 'hover') {
    // const event = new MouseEvent('mouseenter', {
    //   view: window,
    //   bubbles: true,
    //   cancelable: true,
    // })
    // // 派发事件到元素
    // e.dispatchEvent(event)
  } else if (props.trigger === 'click') {
    e.click()
  }
  return unUseTooltip
}

export function getAllKeys(obj: Record<any, any>): Array<string | symbol> {
  const keys = Reflect.ownKeys(obj)
  const parent = Reflect.getPrototypeOf(obj)
  if (parent === Object.prototype) {
    return []
  }
  if (parent) {
    return keys.concat(getAllKeys(parent))
  } else {
    return keys
  }
}
