import { type ExtractPropTypes } from 'vue'
import { buildProps } from '@element-plus/utils'
import { createModelToggleComposable } from '@element-plus/hooks'
import { popperArrowProps, popperProps } from '@element-plus/components/popper'
import { useTooltipContentProps } from './content'
import { useTooltipTriggerProps } from './trigger'
import type { AppContext } from 'vue'

export const {
  useModelToggleProps: useTooltipModelToggleProps,
  useModelToggleEmits: useTooltipModelToggleEmits,
  useModelToggle: useTooltipModelToggle,
} = createModelToggleComposable('visible' as const)

export const useTooltipProps = buildProps({
  ...popperProps,
  ...useTooltipModelToggleProps,
  ...useTooltipContentProps,
  ...useTooltipTriggerProps,
  ...popperArrowProps,
  showArrow: {
    type: Boolean,
    default: true,
  },
  asyncBeforeShow: {
    type: Function,
  },
  asyncBeforeClose: {
    type: Function,
  },
  boolGroupTooltipUncontrolled: {
    type: Boolean,
    default: false,
  },
})

export const tooltipEmits = [
  ...useTooltipModelToggleEmits,
  'before-show',
  'before-hide',
  'show',
  'hide',
  'open',
  'close',
]

export type ElTooltipProps = ExtractPropTypes<typeof useTooltipProps>

export const tooltipAppContext = {
  _context: null as AppContext | null,
}
