<template>
  <teleport to="body" :disabled="!appendToBody">
    <transition
      name="dialog-fade"
      :class="{
        [`${ns.namespace.value}-modal-event-dialog`]: props.modalEvent,
      }"
      @after-enter="afterEnter"
      @after-leave="afterLeave"
      @before-leave="beforeLeave"
    >
      <el-overlay
        v-show="visible"
        custom-mask-event
        :mask="modal"
        :overlay-class="modalClass"
        :z-index="zIndex"
      >
        <div
          role="dialog"
          aria-modal="true"
          :aria-label="title || undefined"
          :aria-labelledby="!title ? titleId : undefined"
          :aria-describedby="bodyId"
          :class="{
            [`${ns.namespace.value}-overlay-dialog`]: true,
            'is-resize': props.zoomY,
            'is-modal-event': props.modalEvent,
          }"
          :style="overlayDialogStyle"
          @click="overlayEvent.onClick"
          @mousedown="overlayEvent.onMousedown"
          @mouseup="overlayEvent.onMouseup"
        >
          <el-focus-trap
            loop
            :trapped="visible"
            focus-start-el="container"
            @focus-after-trapped="onOpenAutoFocus"
            @focus-after-released="onCloseAutoFocus"
            @focusout-prevented="onFocusoutPrevented"
            @release-requested="onCloseRequested"
          >
            <el-dialog-content
              v-if="rendered"
              ref="dialogContentRef"
              v-bind="$attrs"
              :custom-class="customClass"
              :center="center"
              :align-center="alignCenter"
              :close-icon="closeIcon"
              :draggable="draggable"
              :fullscreen="fullscreen"
              :show-close="showClose"
              :cancel-text="cancelText"
              :submit-text="submitText"
              :style="style"
              :size="size"
              :title="title"
              :is-show-footer="isShowFooter"
              :is-show-header="isShowHeader"
              :drag-el="dragEl"
              :default-padding="defaultPadding"
              :top-offest="topOffest"
              @close="handleClose"
              @handle-submit="handleSubmit"
            >
              <div
                v-if="props.zoomY"
                :class="`${ns.namespace.value}-dialog__resize-vertical-up`"
                @mousedown="startMouseMove('up')"
              />
              <div
                v-if="props.zoomY"
                :class="`${ns.namespace.value}-dialog__resize-vertical-down`"
                @mousedown="startMouseMove('down')"
              />
              <template #header>
                <slot
                  v-if="!$slots.title"
                  name="header"
                  :close="handleClose"
                  :title-id="titleId"
                  :title-class="ns.e('title')"
                />
                <slot v-else name="title" />
              </template>
              <template #body-prefix>
                <slot name="body-prefix" />
              </template>
              <slot />
              <template #body-suffix>
                <slot name="body-suffix" />
              </template>
              <template #footer>
                <slot name="footer" />
              </template>
            </el-dialog-content>
          </el-focus-trap>
        </div>
      </el-overlay>
    </transition>
  </teleport>
</template>

<script lang="ts" setup>
import { computed, provide, ref } from 'vue'
import { debounce } from 'lodash'
import { ElOverlay } from '@element-plus/components/overlay'
import { useLocale, useNamespace, useSameTarget } from '@element-plus/hooks'
import { dialogInjectionKey } from '@element-plus/tokens'
import ElFocusTrap from '@element-plus/components/focus-trap'
import { addUnit } from '@element-plus/utils'
import ElDialogContent from './dialog-content.vue'
import { dialogEmits, dialogProps } from './dialog'
import { useDialog } from './use-dialog'

defineOptions({
  name: 'ElDialog',
  inheritAttrs: false,
})

const props = defineProps(dialogProps)
defineEmits(dialogEmits)
// const slots = useSlots()

// useDeprecated(
//   {
//     scope: 'el-dialog',
//     from: 'the title slot',
//     replacement: 'the header slot',
//     version: '3.0.0',
//     ref: 'https://element-plus.org/en-US/component/dialog.html#slots',
//   },
//   computed(() => !!slots.title)
// )

const ns = useNamespace('dialog')
const dialogRef = ref<HTMLElement>()
const headerRef = ref<HTMLElement>()
const dialogContentRef = ref()

const {
  visible,
  titleId,
  bodyId,
  style,
  overlayDialogStyle,
  rendered,
  zIndex,
  afterEnter,
  afterLeave,
  beforeLeave,
  handleClose,
  onModalClick,
  handleSubmit,
  onOpenAutoFocus,
  onCloseAutoFocus,
  onCloseRequested,
  onFocusoutPrevented,
} = useDialog(props, dialogRef)

provide(dialogInjectionKey, {
  dialogRef,
  headerRef,
  bodyId,
  ns,
  rendered,
  style,
})

const overlayEvent = useSameTarget(onModalClick)

const { t } = useLocale()
const cancelText = props.cancelText || t('el.popconfirm.cancelButtonText')
const submitText = props.submitText || t('el.popconfirm.confirmButtonText')
const draggable = computed(() => props.draggable && !props.fullscreen)
const dragEl = computed(() => props.dragEl)
const topOffest = computed(() => props.topOffest)

const startMouseMove = debounce(
  (directtion: 'up' | 'down') => {
    const isUp = directtion === 'up'
    const el = dialogRef.value as HTMLElement
    const rect = el.getBoundingClientRect()
    const { bottom, top, height } = rect
    // dialog有个margin-top，会影响transform的计算
    const MARGIN_TOP = -50
    function mouseMoveTop(e: { clientY: number }) {
      if (isUp) {
        if (props.zoomMaxHeight) {
          const minY = Math.max(bottom - props.zoomMaxHeight, topOffest.value)
          if (e.clientY <= minY) {
            return
          }
        }
        if (props.zoomMinHeight) {
          const maxY = Math.max(bottom - props.zoomMinHeight, 0)
          if (e.clientY >= maxY) {
            return
          }
        }
        const transform = window.getComputedStyle(el).transform
        const transformX =
          transform === 'none' ? 0 : new WebKitCSSMatrix(transform).e
        el.style['transform'] = `translate(${addUnit(transformX)}, ${addUnit(
          e.clientY + MARGIN_TOP
        )})`
        el.style['height'] = `${addUnit(top + height - e.clientY)}`
      } else {
        if (props.zoomMinHeight) {
          const minY = Math.max(top + props.zoomMinHeight, 0)
          if (e.clientY <= minY) {
            return
          }
        }
        if (props.zoomMaxHeight) {
          const maxY = Math.max(top + props.zoomMaxHeight, 0)
          if (e.clientY >= maxY) {
            return
          }
        }
        el.style['height'] = `${addUnit(e.clientY - top)}`
      }
    }
    function removeDocumentMouseup() {
      el.style.userSelect = 'auto'
      document.removeEventListener('mousemove', mouseMoveTop)
      document.removeEventListener('mouseup', removeDocumentMouseup)
    }
    document.addEventListener('mousemove', mouseMoveTop)
    document.addEventListener('mouseup', removeDocumentMouseup)
  },
  50,
  { leading: true }
)

defineExpose({
  /** @description whether the dialog is visible */
  visible,
  dialogContentRef,
})
</script>
