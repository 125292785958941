<template>
  <span
    v-if="disableTransitions"
    :class="classes"
    :style="style"
    @click="handleClick"
  >
    <span :class="ns.e('content')">
      <slot />
    </span>
    <el-icon v-if="closable" :class="ns.e('close')" @click.stop="handleClose">
      <Close />
    </el-icon>
  </span>
  <transition v-else :name="`${ns.namespace.value}-zoom-in-center`" appear>
    <span :class="classes" :style="style" @click="handleClick">
      <span :class="ns.e('content')">
        <slot />
      </span>
      <el-icon v-if="closable" :class="ns.e('close')" @click.stop="handleClose">
        <Close />
      </el-icon>
    </span>
  </transition>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import ElIcon from '@element-plus/components/icon'
import { Close } from '@element-plus/icons-vue'

import { useNamespace, useSize } from '@element-plus/hooks'
import { colorRgb } from '@element-plus/utils/color'
import { tagEmits, tagProps } from './tag'

defineOptions({
  name: 'ElTag',
})
const props = defineProps(tagProps)
const emit = defineEmits(tagEmits)

const tagSize = useSize()
const ns = useNamespace('tag')
const classes = computed(() => {
  const { type, hit, effect, closable, round, border } = props
  return [
    ns.b(),
    ns.is('closable', closable),
    ns.m(type),
    ns.m(tagSize.value),
    ns.m(effect),
    ns.is('hit', hit),
    ns.is('border', border),
    ns.is('round', round),
  ]
})
const style = computed(() => {
  const { color, effect } = props
  if (color) {
    const $color = colorRgb(color)
    if (effect === 'dark' || effect === 'plain') {
      return {
        borderColor: `rgba(${$color}, 1)`,
        backgroundColor: `rgba(${$color}, 1)`,
      }
    }
    return {
      color: `rgba(${$color}, 1)`,
      borderColor: `rgba(${$color}, 0.2)`,
      backgroundColor: `rgba(${$color}, 0.1)`,
    }
  }
  return {}
})

// methods
const handleClose = (event: MouseEvent) => {
  emit('close', event)
}

const handleClick = (event: MouseEvent) => {
  emit('click', event)
}
</script>
