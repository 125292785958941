import { isRef, onBeforeUnmount, onMounted, watchEffect } from 'vue'
import { addUnit } from '@element-plus/utils'
import type { ComputedRef, Ref } from 'vue'

export const useDraggable = (
  targetRef: Ref<HTMLElement | undefined>,
  dragRef: Ref<HTMLElement | undefined> | string,
  draggable: ComputedRef<boolean>,
  topOffest = 0
) => {
  const onMousedown = (e: MouseEvent) => {
    if (!targetRef.value) return
    const downX = e.clientX
    const downY = e.clientY
    // 重写transform获取方式，伸缩会影响到transform的位置，暂存的数据就不可靠了
    let offsetX = 0
    let offsetY = 0
    const transform = window.getComputedStyle(targetRef.value).transform
    if (transform !== 'none') {
      const matrix = new WebKitCSSMatrix(transform)
      offsetX = matrix.e
      offsetY = matrix.f
    }

    const targetRect = targetRef.value.getBoundingClientRect()
    const targetLeft = targetRect.left
    const targetTop = targetRect.top
    const targetWidth = targetRect.width
    const targetHeight = targetRect.height

    const clientWidth = document.documentElement.clientWidth
    const clientHeight = document.documentElement.clientHeight

    const minLeft = -targetLeft + offsetX
    const minTop = -targetTop + offsetY + topOffest
    const maxLeft = clientWidth - targetLeft - targetWidth + offsetX
    const maxTop = clientHeight - targetTop - targetHeight + offsetY

    const onMousemove = (e: MouseEvent) => {
      const moveX = Math.min(
        Math.max(offsetX + e.clientX - downX, minLeft),
        maxLeft
      )
      const moveY = Math.min(
        Math.max(offsetY + e.clientY - downY, minTop),
        maxTop
      )
      targetRef.value!.style.transform = `translate(${addUnit(
        moveX
      )}, ${addUnit(moveY)})`
    }

    const onMouseup = () => {
      document.removeEventListener('mousemove', onMousemove)
      document.removeEventListener('mouseup', onMouseup)
    }

    document.addEventListener('mousemove', onMousemove)
    document.addEventListener('mouseup', onMouseup)
  }

  const onDraggable = () => {
    if (targetRef.value) {
      if (isRef(dragRef)) {
        dragRef.value?.addEventListener('mousedown', onMousedown)
      } else {
        if (dragRef) {
          const dragDom = document.querySelectorAll(dragRef)
          Array.prototype.forEach.call(dragDom, (dom) =>
            dom?.addEventListener('mousedown', onMousedown)
          )
        }
      }
    }
  }

  const offDraggable = () => {
    if (targetRef.value) {
      if (isRef(dragRef)) {
        dragRef.value?.removeEventListener('mousedown', onMousedown)
      } else {
        if (dragRef) {
          const dragDom = document.querySelectorAll(dragRef)
          Array.prototype.forEach.call(dragDom, (dom) =>
            dom?.removeEventListener('mousedown', onMousedown)
          )
        }
      }
    }
  }

  onMounted(() => {
    watchEffect(() => {
      console.log(1)
      draggable.value ? onDraggable() : offDraggable()
    })
  })

  onBeforeUnmount(() => {
    offDraggable()
  })
}
