<template>
  <ElUpload
    v-bind="uploadProps"
    :file-list="fileUrlList"
    :show-file-list="!isSingleUpload"
    :show-draggable="showDraggable"
    :show-preview="showPreview"
    :on-remove="onRemove"
    :on-change="onChange"
    :on-success="onSuccess"
    :on-draggable="onDraggabled"
    :btn-text="btnText"
    list-type="picture-card"
    :on-append-click="onAppendClick"
  >
    <!-- ElUploadList 不要加disabled属性，预览的disabled自己写 -->
    <!-- 有问题问燕姐 -->
    <ElUploadList
      v-if="isSingleUpload && uploadProps.value"
      list-type="picture-card"
      :files="getFileList(uploadProps.value)"
      customer-class="qd-upload-list--single"
      :show-delete="showDelete"
      :on-append-click="onAppendClick"
      :show-preview="showPreview"
      @remove="onRemove"
    />
    <template v-if="$slots.tip" #tip>
      <slot name="tip" />
    </template>
    <template v-if="$slots.default" #default>
      <slot name="default" />
    </template>
    <template v-if="$slots.file" #file>
      <slot name="file" />
    </template>
    <template v-if="$slots.trigger" #trigger>
      <slot name="trigger" />
    </template>
  </ElUpload>
</template>
<script lang="ts">
// todo setup改造，修复tsdefined
import { computed, defineComponent } from 'vue'
import { NOOP } from '@vue/shared'
import { ElUpload } from '@element-plus/components/upload'
import ElUploadList from '@element-plus/components/upload/src/upload-list.vue'

import { uploadListTypes } from '@element-plus/components/upload/src/upload'
import type { PropType } from 'vue'
import type { Nullable } from '@element-plus/utils'
export default defineComponent({
  name: 'ElUploadV2',
  components: {
    ElUpload,
    ElUploadList,
  },
  props: {
    value: {
      type: [Array, String],
      default: () => {
        return ''
      },
    },
    // sync from upload props
    maxFileSize: {
      type: Number,
      default: 3000000,
    },
    // 用来计算需求所需裁剪的比例
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    size: {
      type: Number,
      default: 3,
    },
    action: {
      type: String,
      default: '/v2/file/file/batchUploadImg',
    },
    headers: {
      type: Object as PropType<Headers>,
      default: () => ({}),
    },
    method: {
      type: String,
      default: 'post',
    },
    data: {
      type: Object,
      default: () => ({
        token: localStorage.getItem('token'),
      }),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showCropper: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'file',
    },
    drag: {
      type: Boolean,
      default: false,
    },
    withCredentials: Boolean,
    showFileList: {
      type: Boolean,
      default: true,
    },
    accept: {
      type: String,
      default: 'image/*',
    },
    type: {
      type: String,
      default: 'select', // single
    },
    responseHandle: {
      type: Function,
      default: (file) => file.data.list[0].raw,
    },
    autoUpload: {
      type: Boolean,
      default: true,
    },
    listType: {
      type: String,
      values: uploadListTypes,
      default: 'picture-card',
    },
    httpRequest: {
      type: Function,
    },
    disabled: Boolean,
    limit: {
      type: Number as PropType<Nullable<number>>,
      default: null,
    },
    onExceed: {
      type: Function,
      default: () => NOOP,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    beforeUpload: {
      type: Function,
    },
    showDraggable: {
      type: Boolean,
      default: false,
    },
    onDraggable: {
      type: Function,
      default: () => NOOP,
    },
    onAppendClick: {
      type: Function,
    },
    showPreview: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: '添加图片',
    },
  },
  emits: ['update:value', 'remove'],
  setup(props: any, { emit }) {
    // init and sync fileList
    const isSingleUpload = computed(() => {
      return props.type === 'single'
    })
    const fileUrlList = computed(() => {
      if (isSingleUpload.value) {
        return [{ url: props.value }]
      } else {
        return props.value.map((e) => ({
          url: e,
        }))
      }
    })
    const getEmitValue = (fileList: string[]) => {
      if (isSingleUpload.value) {
        return fileList[0]
      } else {
        return fileList
      }
    }
    const onSuccess = (list: string[]) => {
      emit('update:value', getEmitValue(list))
    }
    const onRemove = (file: any, fileList: any[]) => {
      if (!fileList) {
        emit('update:value', isSingleUpload.value ? '' : [])
        emit('remove', isSingleUpload.value ? '' : [])
        return
      }
      emit('update:value', getEmitValue(fileList.map((e) => e.url)))
      emit('remove', getEmitValue(fileList.map((e) => e.url)))
    }
    const onChange = (list?: string[]) => {
      if (!list) {
        return
      }
      emit('update:value', getEmitValue(list))
    }
    const getFileList = (file: string) => {
      return [{ url: file }]
    }
    const onDraggabled = (fileList: any[]) => {
      const files = getEmitValue(fileList.map((e) => e.url))
      emit('update:value', files)
      props.onDraggable && props.onDraggable(files)
    }
    return {
      uploadProps: props,
      fileUrlList,
      onSuccess,
      onRemove,
      onChange,
      isSingleUpload,
      getFileList,
      onDraggabled,
    }
  },
})
</script>
<style></style>
