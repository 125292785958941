import {
  cloneVNode,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  provide,
} from 'vue'
import { genRandomKey } from '@element-plus/utils'
import { useTooltipProps } from './props'
import { groupToolTipInjectKey, seekParent } from './utils'
import { useTooltip } from './method'

export const GroupTooltip = defineComponent({
  name: 'ElGroupTooltip',
  props: useTooltipProps,
  setup(props, ctx) {
    const uniqueValue = `el-group-tooltip__${genRandomKey()}`
    const instanceData = new WeakMap<HTMLElement, any>()
    const destructionTooltipData = new WeakMap<HTMLElement, any>()
    provide(groupToolTipInjectKey, {
      key: uniqueValue,
      instanceData,
      destructionTooltipFn,
    })
    let root: HTMLElement | null = null
    let lastNode: HTMLElement | null = null
    function destructionTooltipFn(node: HTMLElement) {
      destructionTooltipData.get(node)?.()
    }
    const eventListener = (
      e: MouseEvent,
      eventName: string,
      parent?: boolean
    ) => {
      const node = seekParent(e, uniqueValue, root, parent)
      if (node === lastNode) {
        return
      }
      if (node) {
        const tooltipData = instanceData.get(node)
        if (tooltipData.value.trigger !== eventName) return
        lastNode = node
        if (tooltipData.value) {
          if (tooltipData.value.disabled) {
            e.stopPropagation()
            e.preventDefault()
            node.parentNode && eventListener(e, eventName, true)
            return
          }
          const unUseTooltip = useTooltip(
            {
              ...tooltipData.value,
              onHide: (value: any) => {
                lastNode = null
                tooltipData.value.onHide?.(value)
              },
              closeUseTooltip: () => {
                lastNode = null
              },
            },
            node
          )
          nextTick(() => {
            tooltipData.value.__tooltipRef__.value = unUseTooltip?.ref
          })
          destructionTooltipData.set(node, unUseTooltip)
        }
      }
    }
    const instance = getCurrentInstance()
    onMounted(() => {
      root = instance?.proxy?.$el
    })
    return () => {
      const eventProps: Record<string, unknown> = {
        onClick: (e: MouseEvent) => eventListener(e, 'click'),
        onFocus: (e: MouseEvent) => eventListener(e, 'focus'),
        onMouseover: (e: MouseEvent) => eventListener(e, 'hover'),
      }
      return cloneVNode(ctx.slots.default!()[0], eventProps)
    }
  },
})
