<template>
  <el-tooltip
    ref="tooltipRef"
    v-bind="$attrs"
    :trigger="trigger"
    :placement="placement"
    :disabled="disabled"
    :visible="visible"
    :transition="transition"
    :popper-options="popperOptions"
    :tabindex="tabindex"
    :content="content"
    :offset="offset"
    :show-after="showAfter"
    :hide-after="hideAfter"
    :auto-close="autoClose"
    :show-arrow="showArrow"
    :aria-label="title"
    :effect="effect"
    :enterable="enterable"
    :popper-class="kls"
    :popper-style="style"
    :teleported="teleported"
    :persistent="persistent"
    :gpu-acceleration="gpuAcceleration"
    @update:visible="onUpdateVisible"
    @before-show="beforeEnter"
    @before-hide="beforeLeave"
    @show="afterEnter"
    @hide="afterLeave"
  >
    <template v-if="$slots.reference">
      <slot name="reference" />
    </template>

    <template #content>
      <div v-if="title" :class="ns.e('title')" role="title">
        {{ title }}
      </div>
      <div ref="contentRef" :class="ns.e('contain')">
        <slot>
          {{ content }}
        </slot>
      </div>
    </template>
  </el-tooltip>
</template>
<script lang="ts" setup>
import { computed, nextTick, onMounted, onUnmounted, ref, unref } from 'vue'
import { debounce } from 'lodash-unified'
import { ElTooltip } from '@element-plus/components/tooltip'
import { addUnit } from '@element-plus/utils'
import { useNamespace } from '@element-plus/hooks'
import { popoverEmits, popoverProps } from './popover'
import type { TooltipInstance } from '@element-plus/components/tooltip'

defineOptions({
  name: 'ElPopover',
})

const props = defineProps(popoverProps)
const emit = defineEmits(popoverEmits)

const updateEventKeyRaw = `onUpdate:visible` as const

const onUpdateVisible = computed(() => {
  return props[updateEventKeyRaw]
})

const ns = useNamespace('popover')
const tooltipRef = ref<TooltipInstance>()
const popperRef = computed(() => {
  return unref(tooltipRef)?.popperRef
})

const style = computed(() => {
  return [
    {
      width: addUnit(props.width),
    },
    props.popperStyle!,
  ]
})

const kls = computed(() => {
  return [ns.b(), props.popperClass!, { [ns.m('plain')]: !!props.content }]
})

const gpuAcceleration = computed(() => {
  return props.transition === `${ns.namespace.value}-fade-in-linear`
})

const hide = () => {
  tooltipRef.value?.hide()
}

const beforeEnter = () => {
  emit('before-enter')
}
const beforeLeave = () => {
  emit('before-leave')
}

const afterEnter = () => {
  emit('after-enter')
}

const afterLeave = () => {
  emit('update:visible', false)
  emit('after-leave')
}

defineExpose({
  /** @description popper ref */
  popperRef,
  /** @description hide popover */
  hide,
})

const contentRef = ref<HTMLDivElement>()
let _contentHeight = ''
let _contentWidth = ''
let observer: MutationObserver | null = null
const getWH = () => {
  if (contentRef.value) {
    const { width, height } = getComputedStyle(contentRef.value)
    return { width, height }
  }
  return { width: _contentWidth, height: _contentHeight }
}
onMounted(() => {
  if (contentRef.value && !observer) {
    const { width, height } = getWH()
    _contentWidth = width
    _contentHeight = height
    const ob = new MutationObserver(
      debounce(() => {
        const { width, height } = getWH()
        if (
          (width !== _contentWidth || height !== _contentHeight) &&
          props.visible
        ) {
          _contentWidth = width
          _contentHeight = height
          nextTick(() => {
            tooltipRef.value?.updatePopper()
          })
        }
      }, 50)
    )
    ob.observe(contentRef.value, {
      attributes: true,
      childList: true,
      subtree: true,
    })
    observer = ob
  }
})
onUnmounted(() => {
  observer?.disconnect()
})
</script>
