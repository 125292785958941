import {
  type ExtractPropTypes,
  type Ref,
  computed,
  nextTick,
  ref,
  unref,
  watch,
} from 'vue'
import { throttle } from 'lodash-unified'
import { buildProps, definePropType, isBoolean } from '@element-plus/utils'
import type { UploadCropperFiles } from './upload'
export const croppersEmits = {
  'update:modelValue': (value: boolean) => isBoolean(value),
  cropUploadSuccess: (value: Blob) => isBoolean(value),
}
export interface IsourceImgContainer {
  w: number
  h: number
  r: number
}
export interface IshadeInfo {
  w: number
  h: number
  x: number
  y: number
}
export interface IsourceImgInfo {
  x: number
  y: number
  width: number
  height: number
  top: number
  left: number
  screenX: number
  screenY: number
  initWidth: number
  initHeight: number
  naturalWidth: number
  naturalHeight: number
}
export const uploadCropperProps = buildProps({
  maxFileSize: {
    type: Number,
    default: 3000000,
  },
  // 用来计算需求所需裁剪的比例
  width: {
    type: Number,
    default: 400,
  },
  height: {
    type: Number,
    default: 400,
  },
  size: {
    type: Number,
    default: 3,
  },
} as const)
export const cropperProps = buildProps({
  ...uploadCropperProps,
  modelValue: {
    type: Boolean,
    required: true,
  },
  // 图片源
  file: {
    type: definePropType<UploadCropperFiles>(String),
    required: true,
  },
} as const)
export type CropperProps = ExtractPropTypes<typeof cropperProps>

// watch(()=> {

// })

export const useCropper = (cropperProps: CropperProps) => {
  const isShowDialog = ref(false)
  const sourImgUrl = ref('')
  const sourceImg = ref(null)
  const createImgUrl = ref('')
  const scalingRatio = ref(0)
  const sourceZoomRadius = ref(0)
  const nRatio = ref(cropperProps.width / cropperProps.height)
  const mouseDown = ref(false)
  const sourceImgContainer = ref<IsourceImgContainer>({
    w: 300,
    h: 240,
    // 容器比例
    r: 1.25,
  })
  const shadeInfo = ref<IshadeInfo>({
    w: 0,
    h: 0,
    x: 0,
    y: 0,
  })
  const sourceImgInfo = ref<IsourceImgInfo>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    screenX: 0,
    screenY: 0,
    initWidth: 0,
    initHeight: 0,
    naturalWidth: 0,
    naturalHeight: 0,
  })
  const canvasRef: Ref<HTMLCanvasElement | null> = ref(null)
  const previewImgStyle = computed(() => {
    const ratio = nRatio.value
    return {
      width: '150px',
      height: `${150 / ratio}px`,
    }
  })
  const updateShadeStyle = () => {
    let { w, h } = shadeInfo.value
    const Ratio = nRatio.value
    const { r: sourceR, w: sourceW, h: sourceH } = sourceImgContainer.value
    if (Ratio < sourceR) {
      h = sourceH
      shadeInfo.value.w = w = (sourceW - h * Ratio) / 2
    } else {
      w = sourceW
      shadeInfo.value.h = h = (sourceH - w / Ratio) / 2
    }
    shadeInfo.value.x = w
    shadeInfo.value.y = h
    return {
      w,
      h,
    }
  }
  /**
   *
   * @date 2022-05-24
   * @param {} (
   * @returns {}
   */
  const sourImgStyle = computed(() => {
    const { width, height, top, left } = sourceImgInfo.value
    return {
      width: `${width}px`,
      height: `${height}px`,
      top: `${top}px`,
      left: `${left}px`,
    }
  })
  /** 把图片的url转为file的object
   * 描述
   * @date 2022-05-24
   * @param {any} img:string
   * @returns {any}
   */
  const getImgFile = (img: string) => {
    const image = new Image()
    image.crossOrigin = 'Anonymous' // 解决跨域问题
    image.src = img
    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const context = canvas.getContext('2d')
      context!.drawImage(image, 0, 0, image.width, image.height)
      const dataURL = canvas.toDataURL() // 得到图片的base64编码数据
      const file = dataURLtoFile(dataURL)
      readAsDataURLFun(file)
    }
  }
  const dataURLtoFile = (dataurl: any, filename = '') => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }
  /**
   * 设置源图片
   * @date 2022-05-24
   * @returns {any}
   */
  const setSourceImg = () => {
    if (!cropperProps.file) {
      return
    }
    // 图片上传成功后，再剪切时file是个字符串
    if (typeof cropperProps.file === 'string') {
      getImgFile(cropperProps.file)
    } else {
      readAsDataURLFun(cropperProps.file as File)
    }
  }
  // 读取file流
  const readAsDataURLFun = (file: File) => {
    const fr = new FileReader()
    fr.onload = () => {
      sourImgUrl.value = fr.result as string
      startCrop()
    }
    // 给FileReader对象一个读取完成的方法,使用readAsDataURL会返回一个url,这个值就保存在事件对象的result里,img通过加载这个地址,完成图片的加载
    fr.readAsDataURL(file)
  }
  // 裁剪前的准备，初始化原图 预览图
  const startCrop = () => {
    // 预加载图片
    const img = new Image()
    img.src = sourImgUrl.value
    // 图片加载事件
    img.onload = () => {
      const sourceRadius = img.naturalWidth / img.naturalHeight
      const { w: sourceW, h: sourceH } = unref(sourceImgContainer)
      const { w: shadeW, h: shadeH } = unref(shadeInfo)
      // 图片比例与需求比例作比较 因为容器已经根据需求比例加了遮罩
      // nRatio.value图片的剪切比例
      if (sourceRadius < nRatio.value) {
        sourceImgInfo.value.width = sourceW - shadeW * 2
        sourceImgInfo.value.height = sourceImgInfo.value.width / sourceRadius
        sourceZoomRadius.value = sourceImgInfo.value.width / img.naturalWidth
      } else {
        sourceImgInfo.value.height = sourceH - shadeH * 2
        sourceImgInfo.value.width = sourceImgInfo.value.height * sourceRadius
        sourceZoomRadius.value = sourceImgInfo.value.height / img.naturalHeight
      }
      // 初始化的时候图片在容器中居中显示
      sourceImgInfo.value.left = (sourceW - sourceImgInfo.value.width) / 2
      sourceImgInfo.value.top = (sourceH - sourceImgInfo.value.height) / 2
      sourceImgInfo.value.x = sourceImgInfo.value.left
      sourceImgInfo.value.y = sourceImgInfo.value.top
      // 用来做图片缩放的
      sourceImgInfo.value.initWidth = sourceImgInfo.value.width
      sourceImgInfo.value.initHeight = sourceImgInfo.value.height
      sourceImgInfo.value.naturalWidth = img.naturalWidth
      sourceImgInfo.value.naturalHeight = img.naturalHeight
      if (cropperProps.modelValue) {
        nextTick(() => {
          createImg()
        })
      }
    }
    sourceImg.value = img as any
  }
  // 创建图片
  const createImg = () => {
    const { top, left, naturalWidth, naturalHeight } = unref(sourceImgInfo)
    mouseDown.value = false
    const canvas = canvasRef.value
    const ctx = canvas!.getContext('2d')
    if (!canvas || !ctx) {
      console.error('不支持的画布')
      return
    }
    const ratio = (Number(scalingRatio.value) + 100) / 100
    const { w: sourceW, h: sourceH } = unref(sourceImgContainer)
    const { w: shadeW, h: shadowh } = unref(shadeInfo)
    const createImgWidth = {
      w: (sourceW - 2 * shadeW) / ratio / sourceZoomRadius.value,
      h: (sourceH - 2 * shadowh) / ratio / sourceZoomRadius.value,
      x: (shadeW - left) / ratio / sourceZoomRadius.value,
      y: (shadowh - top) / ratio / sourceZoomRadius.value,
    }
    // 裁剪后的大小 与原图保持一致
    let canvasWidth
    let canvasHeight
    const sourceRadius = naturalWidth / naturalHeight
    if (sourceRadius < nRatio.value) {
      canvasWidth = naturalWidth
      canvasHeight = naturalWidth / nRatio.value
    } else {
      canvasWidth = naturalHeight * nRatio.value
      canvasHeight = naturalHeight
    }
    // 设置画布大小
    canvas.width = canvasWidth
    canvas.height = canvasHeight
    // 清空画布
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(
      sourceImg.value!,
      createImgWidth.x,
      createImgWidth.y,
      createImgWidth.w,
      createImgWidth.h,
      0,
      0,
      canvas.width,
      canvas.height
    )
    const size = data2blob(canvas.toDataURL('image/png')).size
    if (size <= cropperProps.size! * 1024 * 1024) {
      createImgUrl.value = canvas.toDataURL('image/png')
    } else {
      const quality = (cropperProps.size! / size) * 1024 * 1024 * 0.64
      createImgUrl.value = canvas.toDataURL('image/jpeg', quality)
    }
  }
  const data2blob = (data: string) => {
    data = data.split(',')[1]
    data = window.atob(data)
    const ia = new Uint8Array(data.length)
    for (let i = 0; i < data.length; i++) {
      ia[i] = data.charCodeAt(i)
    }
    // canvas.toDataURL 返回的默认格式就是 image/png
    return new Blob([ia], {
      type: 'image/png',
    })
  }
  const clearData = () => {
    sourImgUrl.value = ''
    createImgUrl.value = ''
    sourceZoomRadius.value = 0
    scalingRatio.value = 0
  }
  const startMoveImg = (e: MouseEvent) => {
    sourceImgInfo.value.screenX = e.screenX
    sourceImgInfo.value.screenY = e.screenY
    mouseDown.value = true
  }
  const moveImg = throttle((e: MouseEvent) => {
    e.preventDefault()
    if (!mouseDown.value) {
      return
    }
    const dX = (e.screenX - sourceImgInfo.value.screenX) * 0.1
    const dY = (e.screenY - sourceImgInfo.value.screenY) * 0.1
    let rX = sourceImgInfo.value.left + dX
    let rY = sourceImgInfo.value.top + dY
    const { w: sourceW, h: sourceH } = unref(sourceImgContainer)
    const { w: shadeW, h: shadowh } = unref(shadeInfo)
    // 右移
    if (dX > 0) {
      rX = rX > shadeW ? shadeW : rX
    } else {
      // 左移
      const maxX = shadeW + (2 * (sourceImgInfo.value.width - sourceW)) / 2
      rX = Math.abs(rX) > maxX ? -maxX : rX
    }
    // 下移
    if (dY > 0) {
      rY = rY > shadowh ? shadowh : rY
    } else {
      const maxY = shadowh + (2 * (sourceImgInfo.value.height - sourceH)) / 2
      rY = Math.abs(rY) > maxY ? -maxY : rY
    }
    sourceImgInfo.value.top = rY
    sourceImgInfo.value.left = rX
  }, 16)
  const zoomImg = (type: number) => {
    if (type === -1 && Number(scalingRatio.value) > 0) {
      scalingRatio.value = Number(scalingRatio.value) - 1
    }
    if (type === -2 && Number(scalingRatio.value) < 100) {
      scalingRatio.value = Number(scalingRatio.value) + 1
    }
  }
  // watch(sourceImgContainer, updateShadeStyle)
  // watchEffect(updateShadeStyle)
  const shadeStyle = computed(() => {
    const { w, h } = updateShadeStyle()
    return {
      width: `${w}px`,
      height: `${h}px`,
    }
  })
  watch(
    () => cropperProps.modelValue,
    (value) => {
      isShowDialog.value = value
      if (value) {
        setSourceImg()
      } else {
        clearData()
      }
    }
  )
  watch(scalingRatio, (n) => {
    sourceImgInfo.value.width =
      (sourceImgInfo.value.initWidth * (Number(n) + 100)) / 100
    sourceImgInfo.value.height =
      (sourceImgInfo.value.initHeight * (Number(n) + 100)) / 100
    const { top, left, width, height } = sourceImgInfo.value
    const nTop = -height + sourceImgContainer.value.h - shadeInfo.value.h
    sourceImgInfo.value.top = top < nTop ? nTop : top
    const nLeft = -width + sourceImgContainer.value.w - shadeInfo.value.w
    sourceImgInfo.value.left = left < nLeft ? nLeft : left
    nextTick(() => {
      if (isShowDialog.value) {
        createImg()
      }
    })
  })
  return {
    zoomImg,
    scalingRatio,
    canvasRef,
    createImg,
    data2blob,
    createImgUrl,
    sourImgUrl,
    clearData,
    previewImgStyle,
    shadeStyle,
    sourImgStyle,
    isShowDialog,
    startMoveImg,
    moveImg,
    shadeInfo,
    sourceImgInfo,
  }
}
