import { computed, ref } from 'vue'
import { isClient } from '@vueuse/core'
import { useGlobalConfig } from '../use-global-config'

const zIndex = ref(0)
// mock PopupManage
// let PopupManagerzIndex = ref(2022)
// ;(window as any).PopupManager = {
//   zIndex: PopupManagerzIndex.value,
//   nextZIndex: () => PopupManagerzIndex.value++,
// }

let PopupManager: PopupManagerType | undefined = undefined

export const useZIndex = () => {
  if (isClient) {
    PopupManager = (window as { PopupManager?: PopupManagerType }).PopupManager
  }
  const initialZIndex = useGlobalConfig(
    'zIndex',
    PopupManager ? PopupManager.zIndex : 2000
  ) // TODO: move to @element-plus/constants
  const currentZIndex = computed(() => initialZIndex.value + zIndex.value)

  const nextZIndex = () => {
    zIndex.value++
    if (PopupManager) {
      return PopupManager.nextZIndex()
    }
    return currentZIndex.value
  }

  return {
    initialZIndex,
    currentZIndex,
    nextZIndex,
  }
}

export type PopupManagerType = { zIndex: number; nextZIndex: () => number }
