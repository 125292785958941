import {
  computed,
  defineComponent,
  getCurrentInstance,
  inject,
  onMounted,
  onUnmounted,
  ref,
} from 'vue'
import { ElOnlyChild } from '@element-plus/components/slot'
import { tooltipEmits, useTooltipProps } from './props'
import { groupToolTipInjectKey } from './utils'
import Main from './main.vue'
import type { ComponentInternalInstance } from 'vue'

export type TooltipInstance = InstanceType<typeof Main>

export const Tooltip = defineComponent({
  name: 'ElTooltip',
  props: useTooltipProps,
  emits: tooltipEmits,
  setup(props, ctx) {
    const groupData = inject(groupToolTipInjectKey, null)
    const main = ref<TooltipInstance>()
    const instance = getCurrentInstance() as
      | (ComponentInternalInstance & {
          provides: Record<string, unknown>
        })
      | null
    const computedData = computed(() => {
      return {
        ...(instance!.vnode.props || {}),
        ...props,
        content: ctx.slots.content ? ctx.slots.content : props.content,
        __provides__: instance?.provides,
        __tooltipRef__: main,
      }
    })
    const exposeValue = new Proxy({} as TooltipInstance, {
      get(target, key) {
        return main.value?.[key as unknown as keyof TooltipInstance]
      },
      has(target, p) {
        return main.value ? Reflect.has(main.value, p) : false
      },
    })
    ctx.expose(exposeValue)
    if (groupData && !props.boolGroupTooltipUncontrolled) {
      onMounted(() => {
        groupData.instanceData.set(instance!.proxy!.$el, computedData)
      })
      onUnmounted(() => {
        groupData.instanceData.delete(instance!.proxy!.$el)
        groupData.destructionTooltipFn(instance!.proxy!.$el)
      })
      return () => {
        return (
          <ElOnlyChild class={groupData.key}>
            {ctx.slots.default!()[0]}
          </ElOnlyChild>
        )
      }
    } else {
      const emits = tooltipEmits.reduce((em, item) => {
        em[`on-${item}`.replace(/-(\w)/g, (_, c) => c.toUpperCase())] = (
          ...args: unknown[]
        ) => ctx.emit(item, ...args)
        return em
      }, {} as Record<string, any>)

      return () => {
        return (
          <Main ref={main} v-slots={ctx.slots} {...props} {...emits}></Main>
        )
      }
    }
  },
})
