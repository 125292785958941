<template>
  <el-dialog
    v-model="isShowDialog"
    title="图片剪切"
    :before-close="handleClose"
    width="580px"
    append-to-body
    :submit-handle="confirm"
  >
    <div :class="ns.b()">
      <div :class="clippingNs.b()">
        <div :class="clippingNs.e('picture')">
          <img
            :class="clippingNs.e('picture-img')"
            :src="sourImgUrl"
            :style="sourImgStyle"
            draggable="false"
            @drag="preventDefault"
            @dragstart="preventDefault"
            @dragend="preventDefault"
            @dragleave="preventDefault"
            @dragover="preventDefault"
            @dragenter="preventDefault"
            @mousedown="startMoveImg"
            @mousemove="moveImg"
            @mouseup="createImg"
            @mouseout="createImg"
          />
          <div
            :class="[
              clippingNs.e('picture-shade'),
              clippingNs.em('picture-shade', 'left'),
            ]"
            :style="shadeStyle"
          />
          <div
            :class="[
              clippingNs.e('picture-shade'),
              clippingNs.em('picture-shade', 'right'),
            ]"
            :style="shadeStyle"
          />
        </div>
        <div :class="clippingNs.e('range')">
          <input
            v-model="scalingRatio"
            type="range"
            :step="1"
            :min="0"
            :max="100"
            :class="clippingNs.e('range-input')"
          />
          <span :class="clippingNs.e('range-track')" />
          <i
            :class="[
              clippingNs.e('range-icon'),
              clippingNs.em('range-icon', 'plus'),
            ]"
            @click="zoomImg(-1)"
          />
          <i
            :class="[
              clippingNs.e('range-icon'),
              clippingNs.em('range-icon', 'minus'),
            ]"
            @click="zoomImg(-2)"
          />
        </div>
      </div>
      <div :class="previewNs.b()">
        <div :class="previewNs.e('title')">图片预览：</div>
        <img
          v-if="createImgUrl !== ''"
          :src="createImgUrl"
          :style="previewImgStyle"
        />
      </div>
    </div>
    <canvas v-show="false" ref="canvasRef" />
  </el-dialog>
</template>
<script lang="ts" setup>
import { useNamespace } from '@element-plus/hooks'
import ElDialog from '../../dialog'
import { cropperProps, croppersEmits, useCropper } from './upload-cropper'
defineOptions({
  name: 'ElCropper',
})
const props = defineProps(cropperProps)
const ns = useNamespace('cropper')
const clippingNs = useNamespace('cropper-clipping')
const previewNs = useNamespace('cropper-preview')
const emit = defineEmits(croppersEmits)
const {
  createImgUrl,
  isShowDialog,
  previewImgStyle,
  shadeStyle,
  sourImgStyle,
  scalingRatio,
  canvasRef,
  startMoveImg,
  moveImg,
  zoomImg,
  data2blob,
  createImg,
  sourImgUrl,
} = useCropper(props)

const handleClose = () => {
  emit('update:modelValue', false)
}
const confirm = () => {
  if (!sourImgUrl.value || !createImgUrl.value) {
    return
  }
  const blob = data2blob(createImgUrl.value)
  if (blob) {
    emit('cropUploadSuccess', blob)
    handleClose()
  }
}
const preventDefault = (e: MouseEvent) => {
  e.preventDefault()
  return false
}
</script>
