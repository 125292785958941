import { computed, watch } from 'vue'
import { usePropsAlias } from './use-props-alias'

import type {
  UnidirectionalTransferDataItem,
  UnidirectionalTransferKey,
} from '../transfer'
import type { TransferPanelProps, TransferPanelState } from '../transfer-panel'

export const useCheck = (
  props: TransferPanelProps,
  panelState: TransferPanelState,
  getInTreeCheckData: () => Array<UnidirectionalTransferKey>
) => {
  const propsAlias = usePropsAlias(props)
  const getLastNodeSum = (
    data: UnidirectionalTransferDataItem[],
    sum: number
  ) => {
    return data.reduce(($sum, transferDataItem) => {
      if (
        !transferDataItem[propsAlias.value.children] ||
        !transferDataItem[propsAlias.value.children].length
      ) {
        $sum = $sum + 1
      } else {
        $sum = getLastNodeSum(transferDataItem[propsAlias.value.children], $sum)
      }
      return $sum
    }, sum)
  }

  const getCheckableData = (
    data: UnidirectionalTransferDataItem[],
    checkedData: UnidirectionalTransferDataItem[]
  ): UnidirectionalTransferDataItem[] => {
    data.forEach((transferDataItem) => {
      if (
        !transferDataItem[propsAlias.value.children] ||
        !transferDataItem[propsAlias.value.children].length
      ) {
        if (!transferDataItem[propsAlias.value.disabled]) {
          checkedData.push(transferDataItem)
        }
      } else {
        getCheckableData(
          transferDataItem[propsAlias.value.children],
          checkedData
        )
      }
    })
    return checkedData
  }

  const checkableData = computed<UnidirectionalTransferDataItem[]>(() =>
    getCheckableData(props.data, [])
  )

  const checkedSummary = computed(() => {
    const checkedLength = getInTreeCheckData().length
    const dataLength = getLastNodeSum(props.data, 0)

    return `${checkedLength}/${dataLength}`
  })

  const isIndeterminate = computed(() => {
    const checkedLength = getInTreeCheckData().length
    return checkedLength > 0 && checkedLength < checkableData.value.length
  })

  const updateAllChecked = () => {
    const checkableDataKeys = checkableData.value.map(
      (item: UnidirectionalTransferDataItem) => item[propsAlias.value.key]
    )
    panelState.allChecked =
      checkableDataKeys.length > 0 &&
      checkableDataKeys.every((item: UnidirectionalTransferKey) =>
        getInTreeCheckData().includes(item)
      )
  }

  watch(
    () => panelState.checked,
    () => {
      updateAllChecked()
    }
  )

  watch(checkableData, () => {
    updateAllChecked()
  })

  return {
    checkableData,
    checkedSummary,
    isIndeterminate,
    updateAllChecked,
  }
}
