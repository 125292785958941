import { unref } from 'vue'
import { isArray } from '@element-plus/utils'
import type { Arrayable } from '@element-plus/utils'
import type { InjectionKey, Ref } from 'vue'
import type { TooltipTriggerType } from './trigger'

export const groupToolTipInjectKey: InjectionKey<{
  key: string
  instanceData: WeakMap<HTMLElement, any>
  destructionTooltipFn: (node: HTMLElement) => void
}> = Symbol('groupToolTipKeys')

export const isTriggerType = (
  trigger: Arrayable<TooltipTriggerType>,
  type: TooltipTriggerType
) => {
  if (isArray(trigger)) {
    return trigger.includes(type)
  }
  return trigger === type
}

export const whenTrigger = (
  trigger: Ref<Arrayable<TooltipTriggerType>>,
  type: TooltipTriggerType,
  handler: (e: Event) => void
) => {
  return (e: Event) => {
    isTriggerType(unref(trigger), type) && handler(e)
  }
}

export const seekParent = (
  e: MouseEvent,
  className: string,
  root: HTMLElement | null,
  parent?: boolean
) => {
  const curTarget = e.target as HTMLElement
  if (!root?.contains(curTarget)) {
    return null
  }
  if (!parent && curTarget?.classList.contains(className)) {
    return curTarget
  }
  let nextParent: HTMLElement | null = curTarget?.parentNode as HTMLElement
  let elementDom: HTMLElement | null = null
  while (!elementDom && nextParent) {
    if (nextParent?.classList.contains(className)) {
      elementDom = nextParent
      break
    }
    if (document.body === nextParent || nextParent === root) {
      elementDom = null
      break
    }
    nextParent = nextParent.parentNode as HTMLElement
  }
  return elementDom
}
