<template>
  <div :class="ns.b('panel')">
    <p :class="ns.be('panel', 'header')">
      <el-checkbox
        v-model="allChecked"
        :indeterminate="isIndeterminate"
        :validate-event="false"
        @change="handleAllCheckedChange"
      >
        {{ t('el.transfer.all') }}
        <span>{{ checkedSummary }}</span>
      </el-checkbox>
    </p>
    <div :class="[ns.be('panel', 'body'), ns.be('panel', 'body-left')]">
      <el-input
        v-if="filterable || originLeafNodeIdList >= 10"
        v-model="query"
        :class="ns.be('panel', 'filter')"
        size="default"
        :placeholder="placeholder"
        :icon="Search"
        clearable
        :validate-event="false"
      />
      <el-tree
        v-if="renderTree"
        ref="tree"
        v-model="checked"
        :data="data"
        show-checkbox
        :node-key="propsAlias.key"
        :props="propsAlias"
        :render-content="renderContent"
        :filter-node-method="filterNodeMethod"
        @check="handleCheckChange"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, reactive, ref, toRefs, watch } from 'vue'
import { useLocale, useNamespace } from '@element-plus/hooks'
import { ElCheckbox } from '@element-plus/components/checkbox'
import { ElInput } from '@element-plus/components/input'
import { Search } from '@element-plus/icons-vue'
import { ElTree } from '@element-plus/components/tree'
import { transferPanelEmits, transferPanelProps } from './transfer-panel'
import { useCheck, usePropsAlias } from './composables'
import type { CheckboxValueType } from '@element-plus/components/checkbox'
import type { FilterNodeMethodFunction } from '@element-plus/components/tree/src/tree.type'
import type { UnidirectionalTransferKey } from './transfer'

import type { TransferPanelProps, TransferPanelState } from './transfer-panel'

defineOptions({
  name: 'ElTransferPanel',
})

const props = defineProps(transferPanelProps)
const emit = defineEmits(transferPanelEmits)
const propsAlias = usePropsAlias(props)
const renderTree = ref(true)
const tree = ref<InstanceType<typeof ElTree>>()

const { t } = useLocale()
const ns = useNamespace('unidirectionalTransfer')

const panelState = reactive<TransferPanelState>({
  checked: [],
  allChecked: false,
  query: '',
})

const { isIndeterminate, checkedSummary, checkableData } = useCheck(
  props,
  panelState,
  getInTreeCheckData
)

const originLeafNodeIdList = computed(() => {
  return getLeaf(props.data, 0)
})

const getLeaf = function (data: TransferPanelProps['data'], sum: number) {
  const childKey = props.props.children
  data.forEach((children) => {
    if (children[childKey as string]) {
      sum = getLeaf(children[childKey as string], sum)
    } else {
      sum = sum + 1
    }
  })
  return sum
}

// 获取缓存选项
const getStoreCheck = () => {
  return (props.modelValue || []).filter((item) => {
    return !getNodeData(item)
  })
}

// 获取当前选中项在数结构中的选项
function getInTreeCheckData() {
  return panelState.checked.filter((item) => getNodeData(item))
}

const handleCheckChange = function () {
  const checkedNodes = tree.value?.getCheckedNodes(true) || []
  panelState.checked = [
    ...getStoreCheck(),
    ...(checkedNodes?.map((item) => item[propsAlias.value.key]) || []),
  ]
  panelState.allChecked =
    getInTreeCheckData().length === checkableData.value.length
  const fatherNodeDataList = panelState.checked.map((item) => {
    return tree.value?.getNodePath(item)
  })
  emit(
    'checked-change',
    checkedNodes.map((item, index) => ({
      ...item,
      fatherData: fatherNodeDataList[index]?.slice(
        0,
        fatherNodeDataList[index]?.length || 0 - 1
      ),
    }))
  )
  emit('change', panelState.checked)
}

const handleAllCheckedChange = (value: CheckboxValueType) => {
  panelState.checked = [
    ...getStoreCheck(),
    ...(value
      ? checkableData.value.map((item) => item[propsAlias.value.key])
      : []),
  ]
  tree.value?.setCheckedKeys(panelState.checked)
  const fatherNodeDataList = panelState.checked.map((item) => {
    return tree.value?.getNodePath(item)
  })
  emit(
    'checked-change',
    value
      ? checkableData.value.map((item, index) => ({
          ...item,
          fatherData: fatherNodeDataList[index]?.slice(
            0,
            fatherNodeDataList[index]?.length || 0 - 1
          ),
        }))
      : []
  )
  emit('change', panelState.checked)
}

const filterNodeMethod: FilterNodeMethodFunction = (value, data, node) => {
  if (typeof props.filterMethod === 'function') {
    return props.filterMethod(value, data, node)
  } else {
    const label = String(
      data[propsAlias.value.label] || data[propsAlias.value.key]
    )
    return label.toLowerCase().includes(panelState.query.toLowerCase())
  }
}

const setChecked = function (keys: UnidirectionalTransferKey[]) {
  panelState.checked = keys
  tree.value?.setCheckedKeys(keys)
  nextTick(handleCheckChange)
}

const removeChecked = function (key: UnidirectionalTransferKey) {
  tree.value?.setChecked(key, false, true)
  nextTick(() => {
    handleCheckChange()
  })
}

const { checked, allChecked, query } = toRefs(panelState)

const getNodeData = (key: UnidirectionalTransferKey) => {
  return tree.value?.getNode(key)
}

watch(
  () => query.value,
  () => {
    tree.value?.filter(query.value)
  }
)

watch(
  () => props.data,
  () => {
    nextTick(handleCheckChange)
  }
)

defineExpose({
  setChecked,
  removeChecked,
  getNodeData,
})
</script>
