<template>
  <div
    :class="[ns.b(), ns.m(listType), ns.is('drag', drag)]"
    tabindex="0"
    @click="handleClick()"
    @keydown.self.enter.space="handleKeydown"
  >
    <template v-if="drag">
      <upload-dragger :disabled="disabled" @file="uploadFiles">
        <slot />
      </upload-dragger>
    </template>
    <template v-else>
      <slot>
        <div :class="ns.e('icon')">
          <el-icon size="16"><plus /></el-icon>
        </div>
        <div :class="ns.e('title')">{{ props.btnText }}</div>
      </slot>
    </template>
    <upload-cropper
      v-model="showCropper"
      v-bind="props"
      :file="cropperFile"
      @crop-upload-success="uploadFormDate"
    />
    <input
      ref="inputRef"
      :class="ns.e('input')"
      :name="name"
      :multiple="multiple"
      :accept="accept"
      type="file"
      @change="handleChange"
      @click.stop
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, shallowRef } from 'vue'
import { useDisabled, useNamespace } from '@element-plus/hooks'
import { entriesOf } from '@element-plus/utils'
import { ElIcon } from '@element-plus/components/icon'
import { Plus } from '@element-plus/icons-vue'
import ElMessage from '../../message'
import UploadDragger from './upload-dragger.vue'
import { uploadContentProps } from './upload-content'
import { genFileId } from './upload'
import UploadCropper from './upload-cropper.vue'
import type {
  UploadFile,
  UploadHooks,
  UploadRawFile,
  UploadRequestOptions,
} from './upload'

defineOptions({
  name: 'ElUploadContent',
  inheritAttrs: false,
})
const props = defineProps(uploadContentProps)
const ns = useNamespace('upload')
const disabled = useDisabled()
const updateIndex = ref<undefined | number>(undefined)

const showCropper = ref(false)
const cropperFile = ref<File | string>('')
const requests = shallowRef<Record<string, XMLHttpRequest | Promise<unknown>>>(
  {}
)
const inputRef = shallowRef<HTMLInputElement>()
// 图片上传的beforeUpload的返回值
const hookResult =
  ref<Exclude<ReturnType<UploadHooks['beforeUpload']>, Promise<any>>>()
const boolListIgnore = ref(false)

const uploadFile = async (file: File | Blob) => {
  const { autoUpload, onStart } = props
  const rawFile = file as UploadRawFile
  rawFile.uid = genFileId()
  if (props.beforeUpload) {
    try {
      hookResult.value = await props.beforeUpload(rawFile)
    } catch {
      hookResult.value = false
    }
  } else {
    hookResult.value = undefined
  }
  // 此时列表中将不展示此文件
  const hookResultList = !Array.isArray(hookResult.value)
    ? [hookResult.value]
    : hookResult.value
  boolListIgnore.value = hookResultList.includes('LIST_IGNORE')
  if (!boolListIgnore.value) {
    onStart(rawFile, updateIndex.value)
  }
  if (hookResultList.includes(false)) {
    return
  }
  if (autoUpload) upload(rawFile)
}
const uploadFiles = (files: File[]) => {
  if (files.length === 0) return
  const { limit, fileList, multiple, onExceed } = props
  // 替换图片不需要遵守limit
  if (typeof updateIndex.value !== 'number') {
    if (limit && fileList.length + files.length > limit) {
      onExceed(files, fileList)
      // 还能上传的最多图片数量
      const maxFileNum = limit - fileList.length
      // 截取能上传最大数量的图片
      files = files.slice(0, maxFileNum)
    }
  }

  if (!multiple) {
    files = files.slice(0, 1)
  }
  if (props.showCropper) {
    // openCropper(files[0])
    cropperFile.value = files[0]
    showCropper.value = true
  } else {
    for (const file of files) {
      uploadFile(file)
    }
  }
}
const uploadFormDate = (value: Blob) => {
  uploadFile(value)
}
const upload = async (rawFile: UploadRawFile) => {
  inputRef.value!.value = ''
  if (!props.beforeUpload) {
    if (props.size && props.size * 1024 * 1024 < rawFile.size) {
      ElMessage.warning(`请上传小于${props.size}M的文件`)
      props.revokeObjectURL()
      return
    }
    return doUpload(rawFile)
  }
  let file: File = rawFile
  if (hookResult.value instanceof Blob) {
    if (hookResult.value instanceof File) {
      file = hookResult.value
    } else {
      file = new File(
        !Array.isArray(hookResult.value)
          ? [hookResult.value]
          : hookResult.value,
        rawFile.name,
        {
          type: rawFile.type,
        }
      )
    }
  }
  doUpload(
    Object.assign(file, {
      uid: rawFile.uid,
    })
  )
}

const doUpload = (rawFile: UploadRawFile) => {
  const {
    headers,
    data,
    method,
    withCredentials,
    name: filename,
    action,
    onProgress,
    onSuccess,
    onError,
    httpRequest,
  } = props

  const { uid } = rawFile
  const options: UploadRequestOptions = {
    headers: headers || {},
    withCredentials,
    file: rawFile,
    data,
    method,
    filename,
    action,
    onProgress: (evt) => {
      onProgress(evt, rawFile)
    },
    onSuccess: (res) => {
      onSuccess(res, rawFile, boolListIgnore.value, updateIndex.value)
      delete requests.value[uid]
    },
    onError: (err) => {
      onError(err, rawFile)
      delete requests.value[uid]
    },
  }
  const request = httpRequest(options)
  requests.value[uid] = request
  if (request instanceof Promise) {
    request.then(options.onSuccess, options.onError)
  }
}

const handleChange = (e: Event) => {
  const files = (e.target as HTMLInputElement).files
  if (!files) return
  uploadFiles(Array.from(files))
}

const handleClick = (index?: number) => {
  if (!disabled.value) {
    if (typeof index === 'number') {
      updateIndex.value = index
    } else {
      updateIndex.value = undefined
    }
    if (typeof props.onAppendClick === 'function') {
      props.onAppendClick(index)
      return
    }
    inputRef.value!.value = ''
    inputRef.value!.click()
  }
}

const handleKeydown = () => {
  handleClick()
}

const abort = (file?: UploadFile) => {
  const _reqs = entriesOf(requests.value).filter(
    file ? ([uid]) => String(file.uid) === uid : () => true
  )
  _reqs.forEach(([uid, req]) => {
    if (req instanceof XMLHttpRequest) req.abort()
    delete requests.value[uid]
  })
}

defineExpose({
  abort,
  upload,
  handleClick,
})
</script>
