import { withInstall } from '@element-plus/utils'
import { Tooltip } from './src/render'
import { GroupTooltip } from './src/group-tooltip'
import { tooltipAppContext } from './src/props'
import type { SFCWithInstall } from '@element-plus/utils'
import type { App } from 'vue'
export type { TooltipInstance } from './src/render'

const tooltipInstall = <T>(main: T) => {
  ;(main as SFCWithInstall<T>).install = (app: App): void => {
    tooltipAppContext._context = app._context
    app.component((main as any).name, main as any)
  }
  return main as SFCWithInstall<T>
}
export const ElTooltip = tooltipInstall(Tooltip)

export const ElGroupTooltip = withInstall(GroupTooltip)

export * from './src/props'
export * from './src/trigger'
export * from './src/content'
export * from './src/method'
export default ElTooltip
