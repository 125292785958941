import { buildProps, iconPropType } from '@element-plus/utils'
export const dialogContentProps = buildProps({
  center: {
    type: Boolean,
    default: false,
  },
  alignCenter: {
    type: Boolean,
    default: false,
  },
  closeIcon: {
    type: iconPropType,
  },
  customClass: {
    type: String,
    default: '',
  },
  draggable: {
    type: Boolean,
    default: false,
  },
  fullscreen: {
    type: Boolean,
    default: false,
  },
  showClose: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: '',
  },
  cancelText: {
    type: String,
  },
  submitText: {
    type: String,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  isShowFooter: {
    type: Boolean,
    default: true,
  },
  isShowHeader: {
    type: Boolean,
    default: true,
  },
  size: {
    type: [String, Number],
    default: 'small',
  },
  defaultPadding: {
    type: Boolean,
    default: true,
  },
  dragEl: {
    type: String,
    required: false,
  },
  topOffest: {
    type: Number,
    default: 0,
  },
} as const)

export const dialogContentEmits = {
  close: () => true,
  handleSubmit: (callback: void) => callback,
}
