<template>
  <label
    :class="[
      ns.b(),
      ns.is('disabled', disabled),
      ns.is('focus', focus),
      ns.is('bordered', border),
      ns.is('checked', modelValue === label),
      ns.m(size),
    ]"
  >
    <span
      :class="[
        ns.e('input'),
        ns.is('disabled', disabled),
        ns.is('checked', modelValue === label),
      ]"
    >
      <input
        ref="radioRef"
        v-model="modelValue"
        :class="ns.e('original')"
        :value="label"
        :name="name || radioGroup?.name"
        :disabled="disabled"
        type="radio"
        @focus="focus = true"
        @blur="focus = false"
        @change="handleChange"
      />
      <span :class="ns.e('inner')">
        <el-icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="6"
            viewBox="0 0 8 6"
          >
            <path
              d="M1.578 2.82802L3.125 4.37502L6.813 0.702521C6.96933 0.546188 7.1595 0.496688 7.3835 0.554021C7.6075 0.611355 7.74817 0.752021 7.8055 0.976021C7.86283 1.20002 7.81333 1.39019 7.657 1.54652L3.5475 5.65602C3.43283 5.77069 3.29217 5.82802 3.1255 5.82802C2.95883 5.82802 2.81817 5.77069 2.7035 5.65602L0.718998 3.67152C0.604665 3.54652 0.547332 3.40586 0.546997 3.24952C0.546665 3.09319 0.606499 2.95519 0.726499 2.83552C0.846499 2.71585 0.987165 2.65602 1.1485 2.65602C1.30983 2.65602 1.453 2.71335 1.578 2.82802Z"
              fill="currentColor"
            />
          </svg>
        </el-icon>
      </span>
    </span>
    <span :class="ns.e('label')" @keydown.stop>
      <slot>
        {{ label }}
      </slot>
    </span>
  </label>
</template>

<script lang="ts" setup>
import { nextTick } from 'vue'
import { useNamespace } from '@element-plus/hooks'
import { radioEmits, radioProps } from './radio'
import { useRadio } from './use-radio'

defineOptions({
  name: 'ElRadio',
})
const props = defineProps(radioProps)
const emit = defineEmits(radioEmits)

const ns = useNamespace('radio')
const { radioRef, radioGroup, focus, size, disabled, modelValue } = useRadio(
  props,
  emit
)

function handleChange() {
  nextTick(() => emit('change', modelValue.value))
}
</script>
