import {
  computed,
  defineComponent,
  getCurrentInstance,
  h,
  provide,
  ref,
  watch,
} from 'vue'
import { cloneDeep, debounce, isEqual } from 'lodash'
import { ArrowLeft, ArrowRight } from '@element-plus/icons-vue'
import {
  buildProps,
  definePropType,
  iconPropType,
  isNumber,
  mutable,
} from '@element-plus/utils'
import { useNamespace } from '@element-plus/hooks'
import { elPaginationKey } from '@element-plus/tokens'

import Prev from './components/prev.vue'
import Next from './components/next.vue'
import Sizes from './components/sizes.vue'
import Jumper from './components/jumper.vue'
import Total from './components/total.vue'
import Pager from './components/pager.vue'

import type { ExtractPropTypes, VNode } from 'vue'

/**
 * It it user's responsibility to guarantee that the value of props.total... is number
 * (same as pageSize, defaultPageSize, currentPage, defaultCurrentPage, pageCount)
 * Otherwise we can reasonable infer that the corresponding field is absent
 */
const isAbsent = (v: unknown): v is undefined => typeof v !== 'number'

type LayoutKey =
  | 'prev'
  | 'pager'
  | 'next'
  | 'jumper'
  | '->'
  | 'total'
  | 'sizes'
  | 'slot'

export const paginationProps = buildProps({
  /**
   * @description total item count
   */
  total: Number,
  /**
   * @description options of item count per page
   */
  pageSize: Number,
  /**
   * @description default initial value of page size
   */
  defaultPageSize: {
    type: Number,
    default: 25,
  },
  /**
   * @description current page number
   */
  currentPage: Number,
  /**
   * @description default initial value of current-page
   */
  defaultCurrentPage: Number,
  /**
   * @description total page count. Set either `total` or `page-count` and pages will be displayed; if you need `page-sizes`, `total` is required
   */
  pageCount: Number,
  /**
   * @description number of pagers. Pagination collapses when the total page count exceeds this value
   */
  pagerCount: {
    type: Number,
    validator: (value: unknown) => {
      return (
        isNumber(value) &&
        Math.trunc(value) === value &&
        value > 4 &&
        value < 22 &&
        value % 2 === 1
      )
    },
    default: 7,
  },
  /**
   * @description layout of Pagination, elements separated with a comma
   */
  layout: {
    type: String,
    default: (['total', 'sizes', 'prev', 'pager', 'next'] as LayoutKey[]).join(
      ', '
    ),
  },
  /**
   * @description item count of each page
   */
  pageSizes: {
    type: definePropType<number[]>(Array),
    default: () => mutable([10, 25, 50] as const),
  },
  /**
   * @description custom class name for the page size Select's dropdown
   */
  popperClass: {
    type: String,
    default: '',
  },
  /**
   * @description text for the prev button
   */
  prevText: {
    type: String,
    default: '',
  },
  /**
   * @description icon for the prev button, higher priority of `prev-text`
   */
  prevIcon: {
    type: iconPropType,
    default: () => ArrowLeft,
  },
  /**
   * @description text for the next button
   */
  nextText: {
    type: String,
    default: '',
  },
  /**
   * @description icon for the next button, higher priority of `next-text`
   */
  nextIcon: {
    type: iconPropType,
    default: () => ArrowRight,
  },
  /**
   * @description whether to use small pagination
   */
  small: {
    type: Boolean,
    default: true,
  },
  /**
   * @description whether the buttons have a background color
   */
  background: Boolean,
  /**
   * @description whether Pagination is disabled
   */
  disabled: Boolean,
  /**
   * @description whether to hide when there's only one page
   */
  hideOnSinglePage: Boolean,
  resetCurrentPage: {
    type: Boolean,
    default: true,
  },
  effects: {
    type: [Object, Array, Function, Number, String, Boolean],
    default: undefined,
  },
  effectsDebounce: {
    type: Number,
    default: 500,
  },
} as const)
export type PaginationProps = ExtractPropTypes<typeof paginationProps>

export const paginationEmits = {
  'update:current-page': (val: number) => isNumber(val),
  'update:page-size': (val: number) => isNumber(val),
  'size-change': (val: number) => isNumber(val),
  'current-change': (val: number) => isNumber(val),
  change: (val: { pageNo: number; pageSize: number }) =>
    typeof val.pageNo === 'number' && typeof val.pageSize === 'number',
  'prev-click': (val: number) => isNumber(val),
  'next-click': (val: number) => isNumber(val),
}
export type PaginationEmits = typeof paginationEmits

const componentName = 'ElPagination'
export default defineComponent({
  name: componentName,

  props: paginationProps,
  emits: paginationEmits,

  setup(props, { emit, slots, expose }) {
    const ns = useNamespace('pagination')
    const vnodeProps = getCurrentInstance()!.vnode.props || {}
    // we can find @xxx="xxx" props on `vnodeProps` to check if user bind corresponding events
    const hasCurrentPageListener =
      'onUpdate:currentPage' in vnodeProps ||
      'onUpdate:current-page' in vnodeProps ||
      'onCurrentChange' in vnodeProps
    const hasPageSizeListener =
      'onUpdate:pageSize' in vnodeProps ||
      'onUpdate:page-size' in vnodeProps ||
      'onSizeChange' in vnodeProps

    const innerPageSize = ref(
      isAbsent(props.defaultPageSize)
        ? props.pageSizes?.[0] || 10
        : props.defaultPageSize
    )
    const innerCurrentPage = ref(
      isAbsent(props.defaultCurrentPage) ? 1 : props.defaultCurrentPage
    )

    const pageSizeBridge = computed({
      get() {
        return isAbsent(props.pageSize) ? innerPageSize.value : props.pageSize
      },
      set(v: number) {
        if (isAbsent(props.pageSize)) {
          innerPageSize.value = v
        }
        if (hasPageSizeListener) {
          emit('update:page-size', v)
          emit('size-change', v)
        }
      },
    })

    const pageCountBridge = computed<number>(() => {
      let pageCount = 0
      if (!isAbsent(props.pageCount)) {
        pageCount = props.pageCount
      } else if (!isAbsent(props.total)) {
        pageCount = Math.max(1, Math.ceil(props.total / pageSizeBridge.value))
      }
      return pageCount
    })

    const currentPageBridge = computed<number>({
      get() {
        return isAbsent(props.currentPage)
          ? innerCurrentPage.value
          : props.currentPage
      },
      set(v) {
        let newCurrentPage = v
        if (v < 1) {
          newCurrentPage = 1
        } else if (v > pageCountBridge.value) {
          newCurrentPage = pageCountBridge.value
        }
        if (isAbsent(props.currentPage)) {
          innerCurrentPage.value = newCurrentPage
        }
        if (hasCurrentPageListener) {
          emit('update:current-page', newCurrentPage)
          emit('current-change', newCurrentPage)
        }
        emit('change', {
          pageNo: newCurrentPage,
          pageSize: pageSizeBridge.value,
        })
      },
    })

    watch(pageCountBridge, (val) => {
      if (currentPageBridge.value > val) currentPageBridge.value = val
    })

    function handleCurrentChange(val: number) {
      currentPageBridge.value = val
    }
    const debounceChange = debounce(handleCurrentChange, props.effectsDebounce)
    const effectsRef = computed(() => cloneDeep(props.effects))
    const effectsWatch = (v: any, n: any) => {
      if (isEqual(v, n)) {
        return
      }
      debounceChange(1)
    }
    watch(effectsRef, effectsWatch, { deep: true })

    function handleSizeChange(val: number) {
      pageSizeBridge.value = val
      if (props.resetCurrentPage) {
        const lastPageSize = currentPageBridge.value
        if (lastPageSize !== 1) {
          if (isAbsent(props.currentPage)) {
            innerCurrentPage.value = 1
          }
          if (hasCurrentPageListener) {
            emit('update:current-page', 1)
            emit('current-change', 1)
          }
          emit('change', {
            pageNo: 1,
            pageSize: val,
          })
          return
        }
        emit('change', {
          pageNo: currentPageBridge.value,
          pageSize: val,
        })
        return
      }
      const newPageCount = pageCountBridge.value
      if (currentPageBridge.value > newPageCount) {
        currentPageBridge.value = newPageCount
        return
      }
      emit('change', {
        pageNo: currentPageBridge.value,
        pageSize: val,
      })
    }

    function prev() {
      if (props.disabled) return
      currentPageBridge.value -= 1
      emit('prev-click', currentPageBridge.value)
    }

    function next() {
      if (props.disabled) return
      currentPageBridge.value += 1
      emit('next-click', currentPageBridge.value)
    }

    function addClass(element: any, cls: string) {
      if (element) {
        if (!element.props) {
          element.props = {}
        }
        element.props.class = [element.props.class, cls].join(' ')
      }
    }

    provide(elPaginationKey, {
      pageCount: pageCountBridge,
      disabled: computed(() => props.disabled),
      currentPage: currentPageBridge,
      changeEvent: handleCurrentChange,
      handleSizeChange,
    })
    expose({
      getPageSize: () => pageSizeBridge.value,
      getCurrentPage: () => currentPageBridge.value,
    })

    return () => {
      if (!props.layout) return null
      if (props.hideOnSinglePage && pageCountBridge.value <= 1) return null
      const rootChildren: Array<VNode | VNode[] | null> = []
      const rightWrapperChildren: Array<VNode | VNode[] | null> = []
      const rightWrapperRoot = h(
        'div',
        { class: ns.e('rightwrapper') },
        rightWrapperChildren
      )
      const TEMPLATE_MAP: Record<
        Exclude<LayoutKey, '->'>,
        VNode | VNode[] | null
      > = {
        prev: h(Prev, {
          disabled: props.disabled,
          currentPage: currentPageBridge.value,
          prevText: props.prevText,
          prevIcon: props.prevIcon,
          onClick: prev,
        }),
        jumper: h(Jumper, {
          size: props.small ? 'small' : 'default',
        }),
        pager: h(Pager, {
          currentPage: currentPageBridge.value,
          pageCount: pageCountBridge.value,
          pagerCount: props.pagerCount,
          onChange: handleCurrentChange,
          disabled: props.disabled,
        }),
        next: h(Next, {
          disabled: props.disabled,
          currentPage: currentPageBridge.value,
          pageCount: pageCountBridge.value,
          nextText: props.nextText,
          nextIcon: props.nextIcon,
          onClick: next,
        }),
        sizes: h(Sizes, {
          pageSize: pageSizeBridge.value,
          pageSizes: props.pageSizes,
          popperClass: props.popperClass,
          disabled: props.disabled,
          size: props.small ? 'small' : 'default',
        }),
        slot: slots?.default?.() ?? null,
        total: h(Total, { total: isAbsent(props.total) ? 0 : props.total }),
      }

      const components = props.layout
        .split(',')
        .map((item: string) => item.trim()) as LayoutKey[]

      let haveRightWrapper = false

      components.forEach((c) => {
        if (c === '->') {
          haveRightWrapper = true
          return
        }
        if (!haveRightWrapper) {
          rootChildren.push(TEMPLATE_MAP[c])
        } else {
          rightWrapperChildren.push(TEMPLATE_MAP[c])
        }
      })

      addClass(rootChildren[0], ns.is('first'))
      addClass(rootChildren[rootChildren.length - 1], ns.is('last'))

      if (haveRightWrapper && rightWrapperChildren.length > 0) {
        addClass(rightWrapperChildren[0], ns.is('first'))
        addClass(
          rightWrapperChildren[rightWrapperChildren.length - 1],
          ns.is('last')
        )
        rootChildren.push(rightWrapperRoot)
      }
      return h(
        'div',
        {
          role: 'pagination',
          'aria-label': 'pagination',
          class: [
            ns.b(),
            ns.is('background', props.background),
            {
              [ns.m('small')]: props.small,
            },
          ],
        },
        rootChildren
      )
    }
  },
})
