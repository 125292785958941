import { buildProps, definePropType, isBoolean } from '@element-plus/utils'
import {
  UPDATE_MODEL_EVENT,
  UPDATE_MODEL_VISIBLE,
} from '@element-plus/constants'
import { dialogContentProps } from './dialog-content'

import type { ExtractPropTypes } from 'vue'

type DoneFn = (cancel?: boolean) => void
export type DialogBeforeCloseFn = (done: DoneFn) => void
export type DidlogSumitFn = (callback: VoidFunction) => void

export const dialogProps = buildProps({
  ...dialogContentProps,
  appendToBody: {
    type: Boolean,
    default: false,
  },
  beforeClose: {
    type: definePropType<DialogBeforeCloseFn>(Function),
  },
  submitHandle: {
    type: definePropType<DidlogSumitFn>(Function),
  },
  destroyOnClose: {
    type: Boolean,
    default: false,
  },
  closeOnClickModal: {
    type: Boolean,
    default: false,
  },
  closeOnPressEscape: {
    type: Boolean,
    default: true,
  },
  lockScroll: {
    type: Boolean,
    default: true,
  },
  modal: {
    type: Boolean,
    default: true,
  },
  backgroundClick: {
    type: Boolean,
    default: true,
  },
  openDelay: {
    type: Number,
    default: 0,
  },
  closeDelay: {
    type: Number,
    default: 0,
  },
  top: {
    type: String,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  visible: {
    type: Boolean,
    default: false,
  },
  modalClass: String,
  width: {
    type: [String, Number],
  },
  zIndex: {
    type: Number,
  },
  isShowFooter: {
    type: Boolean,
    default: true,
  },
  isShowHeader: {
    type: Boolean,
    default: true,
  },
  cancelText: {
    type: String,
  },
  submitText: {
    type: String,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  trapFocus: {
    type: Boolean,
    default: false,
  },
  dragEl: String,
  zoomY: Boolean,
  zoomMinHeight: Number,
  zoomMaxHeight: Number,
  topOffest: {
    type: Number,
    default: 0,
  },
  modalEvent: Boolean,
} as const)

export type DialogProps = ExtractPropTypes<typeof dialogProps>

export const dialogEmits = {
  open: () => true,
  opened: () => true,
  close: () => true,
  closed: () => true,
  handleSubmit: () => true,
  [UPDATE_MODEL_EVENT]: (value: boolean) => isBoolean(value),
  [UPDATE_MODEL_VISIBLE]: (value: boolean) => isBoolean(value),
  openAutoFocus: () => true,
  closeAutoFocus: () => true,
  wrapperClose: () => true,
}
export type DialogEmits = typeof dialogEmits
