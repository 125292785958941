<template>
  <i :class="ns.b()" :style="style" v-bind="$attrs">
    <component :is="iconComponent" v-if="iconComponent" />
    <slot />
  </i>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { addUnit, isUndefined } from '@element-plus/utils'
import { useNamespace } from '@element-plus/hooks'
import { ElIcons, iconProps } from './icon'
import type { CSSProperties } from 'vue'

defineOptions({
  name: 'ElIcon',
  inheritAttrs: false,
})
const props = defineProps(iconProps)
const ns = useNamespace('icon')

const iconComponent = computed(() => {
  if (!props.type) {
    return null
  }
  return (
    ElIcons[props.type.replace(/^[A-Za-z]/, (match) => match.toUpperCase())] ||
    null
  )
})

const style = computed<CSSProperties>(() => {
  const { size, color } = props
  if (!size && !color) return {}

  return {
    fontSize: isUndefined(size) ? undefined : addUnit(size),
    '--color': color,
  }
})
</script>
