<template>
  <transition :name="ns.b('fade')">
    <div
      v-show="visible"
      :class="[
        ns.b(),
        ns.m(type),
        ns.m(size),
        ns.is('center', center),
        ns.is('border', border),
        ns.is('radius', radius),
        ns.is(effect),
      ]"
      role="alert"
    >
      <div :class="ns.e('content')">
        <div :class="ns.e('title-content')">
          <el-icon v-if="showIcon && iconComponent" :class="iconClass">
            <template v-if="type !== 'primary'">
              <component :is="iconComponent" />
            </template>
            <template v-else>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
                <circle
                  cx="7.99503"
                  cy="7.99497"
                  r="7.335"
                  fill="#currentColor"
                />
                <ellipse
                  cx="7.99479"
                  cy="4.33998"
                  rx="0.84"
                  ry="0.839979"
                  fill="white"
                />
                <rect
                  x="7.2948"
                  y="6.39328"
                  width="1.4"
                  height="6.20002"
                  rx="0.7"
                  fill="white"
                />
              </svg>
            </template>
          </el-icon>
          <div
            v-if="title || $slots.title"
            :class="[ns.e('title-text'), isBoldTitle]"
          >
            <slot name="title">{{ title }}</slot>
          </div>
        </div>

        <p
          v-if="$slots.default || description"
          :class="[ns.e('description'), isShowIcon]"
        >
          <slot>
            {{ description }}
          </slot>
        </p>
        <template v-if="closable">
          <div
            v-if="closeText"
            :class="[
              ns.e('close-btn'),
              ns.is('customed'),
              { [ns.is('big')]: !!props.description || !!slots.default },
            ]"
            @click="close"
          >
            {{ closeText }}
          </div>
          <el-icon
            v-else
            :class="[
              ns.e('close-btn'),
              { [ns.is('big')]: !!props.description || !!slots.default },
            ]"
            @click="close"
          >
            <Close />
          </el-icon>
        </template>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" setup>
import { computed, ref, useSlots } from 'vue'
import { ElIcon } from '@element-plus/components/icon'
import { TypeComponents, TypeComponentsMap } from '@element-plus/utils'
import { useNamespace } from '@element-plus/hooks'
import { alertEmits, alertProps } from './alert'

const { Close } = TypeComponents

defineOptions({
  name: 'ElAlert',
})

const props = defineProps(alertProps)
const emit = defineEmits(alertEmits)
const slots = useSlots()

const ns = useNamespace('alert')

const visible = ref(true)

const iconComponent = computed(() => TypeComponentsMap[props.type])

const iconClass = computed(() => [
  ns.e('icon'),
  { [ns.is('big')]: !!props.description || !!slots.default },
])

const isShowIcon = computed(() => {
  return { [ns.is('show-icon')]: props.showIcon }
})

const isBoldTitle = computed(() => {
  return { [ns.is('bold')]: props.description || slots.default }
})

const close = (evt: MouseEvent) => {
  visible.value = false
  emit('close', evt)
}
</script>
