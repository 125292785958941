import { computed } from 'vue'

import type { UnidirectionalTransferPropsAlias } from '../transfer'

export const usePropsAlias = (props: {
  props: UnidirectionalTransferPropsAlias
}) => {
  const initProps: Required<UnidirectionalTransferPropsAlias> = {
    label: 'label',
    key: 'key',
    disabled: 'disabled',
    children: 'children',
  }

  return computed(() => ({
    ...initProps,
    ...props.props,
  }))
}
