<template>
  <teleport to="body" :disabled="!appendToBody">
    <transition
      :name="ns.b('fade')"
      @after-enter="afterEnter"
      @after-leave="afterLeave"
      @before-leave="beforeLeave"
    >
      <el-overlay
        v-show="visible"
        :mask="modal"
        :overlay-class="modalClass"
        :z-index="zIndex"
        :background-click="backgroundClick"
        @click="onModalClick"
      >
        <el-focus-trap
          loop
          :trapped="visible"
          :focus-trap-el="drawerRef"
          :focus-start-el="focusStartRef"
          @release-requested="onCloseRequested"
        >
          <div
            ref="drawerRef"
            aria-modal="true"
            :aria-label="title || undefined"
            :aria-labelledby="!title ? titleId : undefined"
            :aria-describedby="bodyId"
            v-bind="$attrs"
            :class="[ns.b(), direction, visible && 'open', customClass]"
            :style="
              isHorizontal ? 'width: ' + drawerSize : 'height: ' + drawerSize
            "
            role="dialog"
            @click.stop
          >
            <span ref="focusStartRef" :class="ns.e('sr-focus')" tabindex="-1" />
            <header v-if="withHeader" :class="ns.e('header')">
              <span
                v-if="showReturn"
                :class="ns.e('header-back')"
                @click="handleReturn"
              >
                <el-icon><Back /></el-icon>
                <span>{{ t('el.pageHeader.title') }}</span>
              </span>
              <slot
                v-if="!$slots.title"
                name="header"
                :close="handleClose"
                :title-id="titleId"
                :title-class="ns.e('title')"
              >
                <span
                  v-if="!$slots.title"
                  :id="titleId"
                  role="heading"
                  :class="ns.e('title')"
                >
                  {{ title }}
                </span>
              </slot>
              <slot v-else name="title">
                <!-- DEPRECATED SLOT -->
              </slot>
              <button
                v-if="showClose"
                :aria-label="t('el.drawer.close')"
                :class="ns.e('close-btn')"
                type="button"
                @click="handleClose"
              >
                <el-icon :class="ns.e('close')" size="16"><close /></el-icon>
              </button>
            </header>
            <template v-if="rendered">
              <slot name="body-prefix" />
              <el-scrollbar
                v-if="bodyScroll"
                :id="bodyId"
                :class="ns.e('body')"
              >
                <slot />
              </el-scrollbar>
              <div v-else :id="bodyId" :class="ns.e('body')">
                <slot />
              </div>
              <slot name="body-suffix" />
            </template>
            <div v-if="isShowFooter" :class="ns.e('footer')">
              <slot name="footer">
                <slot name="footerLeft">
                  <div />
                </slot>
                <slot name="footerRight">
                  <div>
                    <ElButton
                      v-if="cancelText"
                      class="el-drawer__footerBtn"
                      @click="handleClose"
                    >
                      {{ cancelText }}
                    </ElButton>
                    <ElButton
                      :loading="loading"
                      type="primary"
                      class="el-drawer__footerBtn"
                      @click="handleSubmit"
                    >
                      {{ submitText }}
                    </ElButton>
                  </div>
                </slot>
              </slot>
            </div>
          </div>
        </el-focus-trap>
      </el-overlay>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { Back, Close } from '@element-plus/icons-vue'

import { ElOverlay } from '@element-plus/components/overlay'
import ElFocusTrap from '@element-plus/components/focus-trap'
import { ElButton } from '@element-plus/components/button'
import { useDialog } from '@element-plus/components/dialog'
import { addUnit } from '@element-plus/utils'
import ElIcon from '@element-plus/components/icon'
import { useLocale, useNamespace } from '@element-plus/hooks'
import { drawerEmits, drawerProps } from './drawer'

export default defineComponent({
  name: 'ElDrawer',
  components: {
    ElOverlay,
    ElFocusTrap,
    ElIcon,
    ElButton,
    Close,
    Back,
  },
  inheritAttrs: false,
  props: drawerProps,
  emits: drawerEmits,

  setup(props) {
    const drawerRef = ref<HTMLElement>()
    const focusStartRef = ref<HTMLElement>()
    const ns = useNamespace('drawer')
    const { t } = useLocale()

    const isHorizontal = computed(
      () => props.direction === 'rtl' || props.direction === 'ltr'
    )
    const drawerSize = computed(() => addUnit(props.size))

    const cancelText = props.cancelText || t('el.popconfirm.cancelButtonText')
    const submitText = props.submitText || t('el.popconfirm.confirmButtonText')
    const dialogData = useDialog(props, drawerRef, 'drawer')
    const handleReturn = () => {
      if (typeof props.returnHandle === 'function') {
        props.returnHandle(dialogData.handleClose)
      } else {
        dialogData.handleClose()
      }
    }

    return {
      ...dialogData,
      drawerRef,
      focusStartRef,
      isHorizontal,
      drawerSize,
      cancelText,
      submitText,
      handleReturn,
      ns,
      t,
    }
  },
})
</script>
