import { withInstall } from '@element-plus/utils'

import Pagination from './src/pagination'

export const ElPagination = withInstall(Pagination)
export default ElPagination

export type PaginationInstance = InstanceType<typeof ElPagination> & {
  getPageSize: () => number
  getCurrentPage: () => number
}

export * from './src/pagination'
