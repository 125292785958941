import { buildProps, definePropType } from '@element-plus/utils'
import * as Icons from '@element-plus/icons-vue'
import type { DefineComponent, ExtractPropTypes } from 'vue'
import type Icon from './icon.vue'

export const ElIcons: Record<string, DefineComponent> = Icons

export const iconProps = buildProps({
  /**
   * @description SVG icon size, size x size
   */
  size: {
    type: definePropType<number | string>([Number, String]),
  },
  /**
   * @description SVG tag's fill attribute
   */
  color: {
    type: String,
  },

  /**
   * @description Key of Icons, example 'Search'
   */
  type: {
    type: String,
  },
} as const)
export type IconProps = ExtractPropTypes<typeof iconProps>
export type IconInstance = InstanceType<typeof Icon>
