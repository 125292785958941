import { buildProps, definePropType, isArray } from '@element-plus/utils'
import { CHANGE_EVENT } from '@element-plus/constants'
import {
  UnidirectionalTransferCheckedChangeFn,
  UnidirectionalTransferProps,
} from './transfer'

import type { ExtractPropTypes } from 'vue'
import type { UnidirectionalTransferKey } from './transfer'

export interface TransferPanelState {
  checked: UnidirectionalTransferKey[]
  allChecked: boolean
  query: string
}

export const CHECKED_CHANGE_EVENT = 'checked-change'

export const transferPanelProps = buildProps({
  data: UnidirectionalTransferProps.data,
  placeholder: String,
  title: String,
  filterable: Boolean,
  filterMethod: UnidirectionalTransferProps.filterMethod,
  props: UnidirectionalTransferProps.props,
  renderContent: {
    type: Function,
    default: undefined,
  },
  modelValue: {
    type: definePropType<UnidirectionalTransferKey[]>(Array),
    default: () => [],
  },
} as const)
export type TransferPanelProps = ExtractPropTypes<typeof transferPanelProps>

export const transferPanelEmits = {
  [CHANGE_EVENT]: (value: UnidirectionalTransferKey[]) => isArray(value),
  [CHECKED_CHANGE_EVENT]: UnidirectionalTransferCheckedChangeFn,
}
export type TransferPanelEmits = typeof transferPanelEmits
