<template>
  <div :class="ns.b()">
    <transfer-left-panel
      ref="leftPanel"
      :data="props.data"
      :placeholder="panelFilterPlaceholder"
      :filterable="filterable"
      :filter-method="filterMethod"
      :props="props.props"
      :render-content="props.renderContent"
      :model-value="props.modelValue"
      @change="update"
    />
    <div :class="ns.b('panel')">
      <p :class="ns.be('panel', 'header')">
        <slot name="searchContent" :length="modelValue.length">
          <span
            >{{ t('el.transfer.hasChecked') }}（{{ modelValue.length }}）</span
          >
        </slot>
      </p>

      <div :class="ns.be('panel', 'body')">
        <div :class="ns.be('panel', 'container')">
          <div
            v-for="(item, index) in modelValue"
            :key="index"
            :class="ns.be('panel', 'list')"
          >
            <slot
              :key="item"
              name="reftPanelContent"
              :data="checkableData[item]"
            >
              <div>{{ checkableData[item]?.[propsValueMap.label] || '' }}</div>
            </slot>
            <el-icon size="12" @click="removeChecked(item)">
              <Close />
            </el-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { isEqual } from 'lodash'
import { useLocale, useNamespace } from '@element-plus/hooks'
import { Close } from '@element-plus/icons-vue'
import {
  DEFAULT_PROPS,
  UnidirectionalTransferEmits,
  UnidirectionalTransferProps,
} from './transfer'
import TransferLeftPanel from './transfer-left-plane.vue'
import type {
  UnidirectionalTransferDataItem,
  UnidirectionalTransferKey,
} from './transfer'

defineOptions({
  name: 'ElUnidirectionalTransfer',
})

const leftPanel = ref<InstanceType<typeof TransferLeftPanel>>()

const props = defineProps(UnidirectionalTransferProps)
const emit = defineEmits(UnidirectionalTransferEmits)

const { t } = useLocale()
const ns = useNamespace('unidirectionalTransfer')

const propsValueMap = computed(() => {
  return Object.assign({}, DEFAULT_PROPS, props.props)
})
let checkedData: UnidirectionalTransferDataItem = {}
const getCheckableData = (
  data: UnidirectionalTransferDataItem[]
): UnidirectionalTransferDataItem => {
  data.forEach((transferDataItem) => {
    if (
      !transferDataItem[propsValueMap.value.children] ||
      !transferDataItem[propsValueMap.value.children].length
    ) {
      if (!transferDataItem[propsValueMap.value.disabled]) {
        checkedData[transferDataItem[propsValueMap.value.key]] =
          transferDataItem
      }
    } else {
      getCheckableData(transferDataItem[propsValueMap.value.children])
    }
  })
  return checkedData
}

const checkableData = computed<UnidirectionalTransferDataItem>(() => {
  checkedData = {}
  return getCheckableData(props.data)
})

const removeChecked = (key: UnidirectionalTransferKey) => {
  if (!leftPanel.value?.getNodeData(key)) {
    update(props.modelValue.filter((item) => item !== key))
    return
  }
  leftPanel.value?.removeChecked(key)
}

const panelFilterPlaceholder = computed(
  () => props.filterPlaceholder || t('el.transfer.filterPlaceholder')
)
let emitValue = [...props.modelValue]
let firstCheck = false
const initModelValue = [...props.modelValue]
function update(key: UnidirectionalTransferKey[]) {
  if (!firstCheck) {
    const nonexistent = Array.from(
      new Set([...initModelValue, ...props.modelValue])
    ).filter((item) => !key.includes(item))
    emit('change-nonexistent', nonexistent)
  }
  firstCheck = true
  emitValue = key
  emit('update:modelValue', key)
  emit('change', key)
}

watch(
  () => props.data,
  () => {
    firstCheck = false
  }
)

onMounted(() => {
  props.modelValue &&
    leftPanel.value &&
    leftPanel.value?.setChecked(props.modelValue)
})

watch(
  () => props.modelValue,
  (keys, oldValue) => {
    if (!isEqual(keys, oldValue) && !isEqual(keys, emitValue)) {
      leftPanel.value?.setChecked(keys)
    }
  }
)
function setChecked(key: UnidirectionalTransferKey[]) {
  leftPanel.value?.setChecked(key)
}
defineExpose({
  setChecked,
})
</script>
