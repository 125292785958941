// @ts-nocheck
import {
  defineComponent,
  getCurrentInstance,
  h,
  inject,
  nextTick,
  onMounted,
  ref,
} from 'vue'
import ElCheckbox from '@element-plus/components/checkbox'
import { useNamespace } from '@element-plus/hooks'
import FilterPanel from '../filter-panel.vue'
import useLayoutObserver from '../layout-observer'
import { TABLE_INJECTION_KEY } from '../tokens'
import useEvent from './event-helper'
import useStyle from './style.helper'
import useUtils from './utils-helper'
import type { ComponentInternalInstance, PropType, Ref } from 'vue'
import type { DefaultRow, Sort } from '../table/defaults'
import type { Store } from '../store'
export interface TableHeader extends ComponentInternalInstance {
  state: {
    onColumnsChange
    onScrollableChange
  }
  filterPanels: Ref<unknown>
}
export interface TableHeaderProps<T> {
  fixed: string
  store: Store<T>
  border: boolean
  defaultSort: Sort
}

export default defineComponent({
  name: 'ElTableHeader',
  components: {
    ElCheckbox,
  },
  props: {
    fixed: {
      type: String,
      default: '',
    },
    store: {
      required: true,
      type: Object as PropType<TableHeaderProps<DefaultRow>['store']>,
    },
    border: Boolean,
    defaultSort: {
      type: Object as PropType<TableHeaderProps<DefaultRow>['defaultSort']>,
      default: () => {
        return {
          prop: '',
          order: '',
        }
      },
    },
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance() as TableHeader
    const parent = inject(TABLE_INJECTION_KEY)
    const ns = useNamespace('table')
    const filterPanels = ref({})
    const { onColumnsChange, onScrollableChange } = useLayoutObserver(parent!)
    onMounted(async () => {
      // Need double await, because udpateColumns is executed after nextTick for now
      await nextTick()
      await nextTick()
      const { prop, order } = props.defaultSort
      parent?.store.commit('sort', { prop, order, init: true })
    })
    const {
      handleHeaderClick,
      handleHeaderContextMenu,
      handleMouseDown,
      handleMouseMove,
      handleMouseOut,
      handleSortClick,
      handleFilterClick,
    } = useEvent(props as TableHeaderProps<unknown>, emit)
    const {
      getHeaderRowStyle,
      getHeaderRowClass,
      getHeaderCellStyle,
      getHeaderCellClass,
    } = useStyle(props as TableHeaderProps<unknown>)
    const { isGroup, toggleAllSelection, columnRows } = useUtils(
      props as TableHeaderProps<unknown>
    )

    instance.state = {
      onColumnsChange,
      onScrollableChange,
    }
    instance.filterPanels = filterPanels

    const handleFixedClick = (event, conlumn, fixedType) => {
      event.stopPropagation()
      conlumn.fixed = fixedType
      parent?.store.scheduleLayout(true)
      // parent.state.doLayout()
    }

    return {
      ns,
      filterPanels,
      onColumnsChange,
      onScrollableChange,
      columnRows,
      getHeaderRowClass,
      getHeaderRowStyle,
      getHeaderCellClass,
      getHeaderCellStyle,
      handleHeaderClick,
      handleHeaderContextMenu,
      handleMouseDown,
      handleMouseMove,
      handleMouseOut,
      handleSortClick,
      handleFilterClick,
      handleFixedClick,
      isGroup,
      toggleAllSelection,
    }
  },
  render() {
    const {
      ns,
      isGroup,
      columnRows,
      getHeaderCellStyle,
      getHeaderCellClass,
      getHeaderRowClass,
      getHeaderRowStyle,
      handleHeaderClick,
      handleHeaderContextMenu,
      handleMouseDown,
      handleMouseMove,
      handleSortClick,
      handleMouseOut,
      handleFixedClick,
      store,
      $parent,
    } = this
    let rowSpan = 1
    return h(
      'thead',
      {
        class: { [ns.is('group')]: isGroup },
      },
      columnRows.map((subColumns, rowIndex) =>
        h(
          'tr',
          {
            class: getHeaderRowClass(rowIndex),
            key: rowIndex,
            style: getHeaderRowStyle(rowIndex),
          },
          subColumns.map((column, cellIndex) => {
            if (column.rowSpan > rowSpan) {
              rowSpan = column.rowSpan
            }
            return h(
              'th',
              {
                class: getHeaderCellClass(
                  rowIndex,
                  cellIndex,
                  subColumns,
                  column
                ),
                colspan: column.colSpan,
                key: `${column.id}-thead`,
                rowspan: column.rowSpan,
                style: getHeaderCellStyle(
                  rowIndex,
                  cellIndex,
                  subColumns,
                  column
                ),
                onClick: ($event) => handleHeaderClick($event, column),
                onContextmenu: ($event) =>
                  handleHeaderContextMenu($event, column),
                onMousedown: ($event) => handleMouseDown($event, column),
                onMousemove: ($event) => handleMouseMove($event, column),
                onMouseout: handleMouseOut,
              },
              [
                h(
                  'div',
                  {
                    class: [
                      'cell',
                      column.filteredValue && column.filteredValue.length > 0
                        ? 'highlight'
                        : '',
                    ],
                  },
                  [
                    column.renderHeader
                      ? column.renderHeader({
                          column,
                          $index: cellIndex,
                          store,
                          _self: $parent,
                        })
                      : column.label,
                    column.sortable &&
                      h(
                        'span',
                        {
                          onClick: ($event) => handleSortClick($event, column),
                          class: 'caret-wrapper',
                        },
                        [
                          h('i', {
                            onClick: ($event) =>
                              handleSortClick($event, column, 'ascending'),
                            class: 'sort-caret ascending',
                          }),
                          h('i', {
                            onClick: ($event) =>
                              handleSortClick($event, column, 'descending'),
                            class: 'sort-caret descending',
                          }),
                        ]
                      ),
                    column.filterable &&
                      h(FilterPanel, {
                        store,
                        placement: column.filterPlacement || 'bottom-start',
                        column,
                        upDataColumn: (key, value) => {
                          column[key] = value
                        },
                      }),
                    column.fixed === 'right' &&
                      h(
                        'span',
                        {
                          style:
                            'display: inline-flex;cursor: pointer;margin-left: 8px;vertical-align: middle',
                          onClick: (e) =>
                            handleFixedClick(e, column, 'notfixed'),
                        },
                        [
                          h(
                            'svg',
                            {
                              class: 'icon',
                              viewBox: '0 0 1024 1024',
                              version: '1.1',
                              xmlns: 'http://www.w3.org/2000/svg',
                              pId: '21690',
                              width: '12px',
                              height: '12px',
                            },
                            [
                              h(
                                'path',
                                {
                                  pId: '21691',
                                  fill: '#053DC8',
                                  d: 'M568.96 141.44a32 32 0 0 1 52.48-23.808l240.896 202.112a32 32 0 0 1-14.336 55.936l-84.8 16.832L648.32 609.28a158.08 158.08 0 0 1 6.72 210.752 21.12 21.12 0 0 1-29.696 2.624l-155.456-130.432-254.848 212.864a38.208 38.208 0 0 1-57.6-48.32l165.376-288L167.36 438.4a21.12 21.12 0 0 1-2.56-29.696 158.144 158.144 0 0 1 208.64-30.08L567.04 227.968l1.92-86.4zM325.888 241.92a64 64 0 1 0 0-128 64 64 0 0 0 0 128z',
                                },
                                ''
                              ),
                            ]
                          ),
                        ]
                      ),
                    column.fixed === 'notfixed' &&
                      h(
                        'span',
                        {
                          style:
                            'display: inline-flex;cursor: pointer;margin-left: 8px;vertical-align: middle',
                          onClick: (e) => handleFixedClick(e, column, 'right'),
                        },
                        [
                          h(
                            'svg',
                            {
                              class: 'icon',
                              viewBox: '0 0 1024 1024',
                              version: '1.1',
                              xmlns: 'http://www.w3.org/2000/svg',
                              pId: '21888',
                              width: '12px',
                              height: '12px',
                            },
                            [
                              h(
                                'path',
                                {
                                  pId: '21889',
                                  fill: '#666666',
                                  d: 'M354.752 64a32 32 0 0 0-24.96 52.032L384 183.488l-51.392 239.872A158.08 158.08 0 0 0 192 580.544c0 11.648 9.408 21.056 21.12 21.056H416l58.368 326.912a38.208 38.208 0 0 0 75.264 0L608 601.6h202.88a21.12 21.12 0 0 0 21.12-21.12c0-81.344-61.504-148.416-140.608-157.12L640 183.488l54.208-67.456a32 32 0 0 0-24.96-52.032H354.752z',
                                },
                                ''
                              ),
                            ]
                          ),
                        ]
                      ),
                  ]
                ),
              ]
            )
          })
        )
      )
    )
  },
})
