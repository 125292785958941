<template>
  <el-draggable
    v-model="fileList"
    :class="[
      nsUpload.b('list'),
      nsUpload.bm('list', listType),
      { 'is-disabled': disabled },
      { 'is-draggable': props.showDraggable },
      customerClass,
    ]"
    :handle="'.' + nsUpload.be('list', 'item')"
    :disabled="!props.showDraggable"
    :move="handelMove"
    @change="draggable"
  >
    <li
      v-for="(file, index) in fileList"
      :key="file.uid || file.name"
      :class="[
        nsUpload.be('list', 'item'),
        nsUpload.is(file.status),
        { focusing },
      ]"
      tabindex="0"
      @keydown.delete="!disabled && handleRemove(file)"
      @focus="focusing = true"
      @blur="focusing = false"
      @click="handelClick(index)"
    >
      <slot :file="file">
        <img
          v-if="file.status !== 'uploading' && listType === 'picture-card'"
          :class="nsUpload.be('list', 'item-thumbnail')"
          :src="file.url"
          alt=""
        />
        <div
          v-if="file.status === 'uploading' || listType !== 'picture-card'"
          :class="nsUpload.be('list', 'item-info')"
        >
          <div
            :class="nsUpload.be('list', 'item-name')"
            @click.prevent="handlePreview(file)"
          >
            <div :class="nsUpload.be('list', 'item-name-label')">
              <img
                v-if="file.status !== 'uploading' && listType === 'picture'"
                :class="nsUpload.be('list', 'picture')"
                :src="file.url"
                alt=""
              />
              <el-icon v-else :class="nsIcon.m('document')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.7922 4.15937C13.0563 3.43281 12.0781 3.02969 11.0344 3.02813H11.0235C10.5219 3.02813 10.0328 3.12188 9.5672 3.30625C9.08439 3.49844 8.65001 3.78125 8.27814 4.14844L4.63908 7.74531C4.21876 8.16094 3.98908 8.71563 3.99064 9.30781C3.9922 9.89844 4.22501 10.4531 4.64689 10.8703C5.06876 11.2875 5.62814 11.5172 6.22501 11.5188H6.2297C6.82501 11.5188 7.38283 11.2906 7.80158 10.8781L10.9953 7.72188C11.1016 7.61719 11.1594 7.47813 11.1594 7.33125C11.1594 7.18281 11.1016 7.04375 10.9953 6.94063C10.8906 6.83594 10.75 6.77969 10.6016 6.77969C10.4531 6.77969 10.3125 6.8375 10.2078 6.94063L7.01408 10.0969C6.80626 10.3031 6.52658 10.4156 6.22814 10.4156H6.22658C5.92501 10.4141 5.6422 10.2984 5.43283 10.0906C5.22189 9.88125 5.1047 9.60312 5.1047 9.30625C5.10314 9.01094 5.2172 8.73438 5.42658 8.52812L9.06564 4.92969C9.58439 4.41563 10.2813 4.13281 11.025 4.13281H11.0328C11.7797 4.13438 12.4813 4.42188 13.0078 4.94062C13.5328 5.46094 13.8235 6.15312 13.825 6.89062C13.8266 7.62813 13.5406 8.31875 13.0203 8.83281L9.15939 12.6531C8.38751 13.4125 7.35626 13.8313 6.25626 13.8313H6.24533C5.14064 13.8281 4.10314 13.4031 3.32501 12.6344C2.54689 11.8656 2.1172 10.8406 2.11564 9.74844C2.11251 8.65625 2.53751 7.63437 3.30939 6.87187L8.29376 1.94375C8.40001 1.83906 8.45783 1.7 8.45783 1.55313C8.45783 1.40469 8.40001 1.26562 8.29376 1.1625C8.18751 1.05781 8.04689 1 7.89845 1C7.75001 1 7.60939 1.05781 7.5047 1.16094L2.52033 6.09062C2.02345 6.58125 1.63908 7.15781 1.37658 7.80156C1.12501 8.42344 0.998452 9.07969 1.00001 9.75156C1.00158 10.425 1.13126 11.0813 1.38751 11.7016C1.65158 12.3453 2.03751 12.9219 2.53751 13.4156C3.03439 13.9078 3.61876 14.2906 4.27033 14.5531C4.89533 14.8047 5.55939 14.9328 6.2422 14.9359H6.25626C6.93439 14.9359 7.5922 14.8109 8.21408 14.5625C8.86408 14.3047 9.44689 13.925 9.94376 13.4328L13.8047 9.61406C14.175 9.24687 14.4625 8.81875 14.6563 8.33906C14.8438 7.875 14.9391 7.3875 14.9375 6.88594C14.9328 5.85469 14.5266 4.88594 13.7922 4.15937Z"
                    fill="#333333"
                  />
                </svg>
              </el-icon>
              <span :class="nsUpload.be('list', 'item-file-name')">
                {{ file.name }}
              </span>
            </div>
            <el-icon
              v-if="file.status === 'uploading'"
              :class="[[nsIcon.m('loading')], 'is-loading']"
            >
              <Loading />
            </el-icon>
            <el-icon
              v-if="file.status !== 'uploading' && file.url"
              :class="[nsIcon.m('download')]"
              @click="download(file)"
            >
              <Download />
            </el-icon>
          </div>
        </div>
        <label
          v-if="listType === 'text'"
          :class="nsUpload.be('list', 'item-status-label')"
        >
          <el-icon
            :class="[nsIcon.m('upload-success'), nsIcon.m('circle-check')]"
          >
            <circle-check />
          </el-icon>
          <el-icon
            v-if="['picture-card', 'picture'].includes(listType)"
            :class="[nsIcon.m('upload-success'), nsIcon.m('check')]"
          >
            <Check />
          </el-icon>
        </label>
        <el-icon
          v-if="!disabled"
          :class="nsIcon.m('close')"
          size="14"
          @click.stop="handleRemove(file)"
        >
          <Delete />
        </el-icon>
        <span
          v-if="listType === 'picture-card'"
          :class="nsUpload.be('list', 'item-actions')"
        >
          <span
            v-if="!disabled && showPreview"
            :class="nsUpload.be('list', 'item-preview')"
            @click.stop="openPreview(index)"
          >
            <el-icon :class="nsIcon.m('preview')" size="16">
              <ZoomIn />
            </el-icon>
          </span>
          <span v-if="!disabled" :class="nsUpload.be('list', 'item-edit')">
            <el-icon :class="nsIcon.m('edit')" size="16">
              <Edit />
            </el-icon>
          </span>
          <span
            v-if="!disabled && showDelete"
            :class="nsUpload.be('list', 'item-delete')"
            @click.stop="handleRemove(file)"
          >
            <el-icon :class="nsIcon.m('delete')" size="16">
              <CircleCloseFilled />
            </el-icon>
          </span>
        </span>
      </slot>
    </li>
    <slot name="append" />
    <image-viewer
      v-if="showViewer"
      :url-list="previewSrcList"
      :initial-index="initialIndex"
      :teleported="true"
      @close="showViewer = false"
    />
  </el-draggable>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useVModel } from '@vueuse/core'
import ImageViewer from '@element-plus/components/image-viewer'
import { ElIcon } from '@element-plus/components/icon'
import {
  Check,
  CircleCheck,
  CircleCloseFilled,
  Delete,
  Download,
  Edit,
  Loading,
  ZoomIn,
} from '@element-plus/icons-vue'
import { useNamespace } from '@element-plus/hooks'
// import ElProgress from '@element-plus/components/progress'
import ElDraggable from '@element-plus/components/draggable'

import { uploadListEmits, uploadListProps } from './upload-list'
import type { UploadFile } from './upload'

defineOptions({
  name: 'ElUploadList',
})

const props = defineProps(uploadListProps)
const emit = defineEmits(uploadListEmits)

const fileList = useVModel(props, 'files', undefined, { passive: true })

const nsUpload = useNamespace('upload')
const nsIcon = useNamespace('icon')

const focusing = ref(false)

const handleRemove = (file: UploadFile) => {
  emit('remove', file)
}
const handelClick = (index: number) => {
  focusing.value = false
  props.uploadRef?.handleClick(index)
}
const download = (file: UploadFile) => {
  window.open(
    `${file?.url}${
      file?.url ? `?attname=${encodeURIComponent(file.name)}` : ''
    }`,
    '_blank'
  )
}
const draggable = () => {
  emit('draggable', fileList.value)
}
const handelMove = (e: { relatedContext: { element: boolean } }) => {
  if (!e.relatedContext.element) {
    return false
  }
}
const showViewer = ref(false)
const initialIndex = ref(0)
const previewSrcList = computed(() => {
  const urlList: string[] = []
  fileList.value.forEach((e) => {
    e.url && urlList.push(e.url)
  })
  return urlList
})
const openPreview = (index: number) => {
  initialIndex.value = index
  showViewer.value = true
}
</script>
