import { buildProps, definePropType } from '@element-plus/utils'
import { timePanelSharedProps } from './shared'

import type { ExtractPropTypes } from 'vue'
import type { Dayjs } from 'dayjs'

export const panelTimeRangeProps = buildProps({
  ...timePanelSharedProps,
  parsedValue: {
    type: definePropType<[Dayjs, Dayjs]>(Array),
  },
  acrossDays: Boolean,
  enableStartSyncEnd: {
    type: definePropType<boolean | number>([Boolean, Number]),
    default: false,
  },
  enableStartSyncEndToBefore: {
    type: definePropType<boolean | number>([Boolean, Number]),
    default: false,
  },
} as const)

export type PanelTimeRangeProps = ExtractPropTypes<typeof panelTimeRangeProps>
