import {
  buildProps,
  definePropType,
  isArray,
  mutable,
} from '@element-plus/utils'
import { CHANGE_EVENT, UPDATE_MODEL_EVENT } from '@element-plus/constants'
import type Node from '@element-plus/components/tree/src/model/node'

export type UnidirectionalTransferKey = string | number

export type UnidirectionalTransferDataItem = Record<string, any>

export interface UnidirectionalTransferPropsAlias {
  label?: string
  key?: string
  disabled?: string
  children?: string
}

export const DEFAULT_PROPS = {
  label: 'label',
  key: 'key',
  disabled: 'disabled',
  children: 'children',
} as const

export const UnidirectionalTransferProps = buildProps({
  data: {
    type: definePropType<UnidirectionalTransferDataItem[]>(Array),
    default: () => [],
  },
  filterPlaceholder: String,
  filterMethod: {
    type: definePropType<
      (value: any, data: { [key: string]: any }, child: Node) => boolean
    >(Function),
  },
  modelValue: {
    type: definePropType<UnidirectionalTransferKey[]>(Array),
    default: () => [],
  },
  filterable: Boolean,
  props: {
    type: definePropType<UnidirectionalTransferPropsAlias>(Object),
    default: () => mutable(DEFAULT_PROPS),
  },
  renderContent: {
    type: Function,
    default: undefined,
  },
} as const)

export const UnidirectionalTransferCheckedChangeFn = (
  value: UnidirectionalTransferDataItem[]
) => [value].every(isArray)

export const UnidirectionalTransferEmits = {
  [CHANGE_EVENT]: (value: UnidirectionalTransferKey[]) => isArray(value),
  [UPDATE_MODEL_EVENT]: (value: UnidirectionalTransferKey[]) => isArray(value),
  'change-nonexistent': (value: UnidirectionalTransferKey[]) => isArray(value),
}
