<template>
  <div
    :class="[ns.b('dragger'), ns.is('dragover', dragover)]"
    @drop.prevent="onDrop"
    @dragover.prevent="onDragover"
    @dragleave.prevent="dragover = false"
  >
    <slot>
      <el-icon size="24">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.38509 11.6169C3.66224 11.6507 2.94873 11.4432 2.35746 11.0272C1.29724 10.2796 0.792815 8.96895 1.07927 7.70603C1.36573 6.44311 2.38669 5.47647 3.66637 5.25658C3.88999 5.22285 4.11615 5.20876 4.34224 5.21446C4.42672 5.21446 4.46897 5.21446 4.46897 5.13022C4.85426 3.81294 5.94703 2.82183 7.29859 2.56383C8.56784 2.26644 9.89837 2.68773 10.7631 3.66079C11.163 4.07423 11.4533 4.58048 11.6079 5.13383C11.6145 5.14705 11.6201 5.15923 11.6252 5.17021C11.6522 5.22922 11.6634 5.25358 11.7346 5.21807C13.4278 5.15247 14.8674 6.43904 14.9867 8.12444C15.131 9.7096 14.0887 11.1609 12.5372 11.5351C12.2184 11.6048 11.8918 11.6331 11.5657 11.6193H11.2283C10.995 11.6193 10.8059 11.4307 10.8059 11.1981C10.7969 11.0798 10.8371 10.963 10.9169 10.8751C10.9968 10.7871 11.1094 10.7357 11.2283 10.7329H11.9887C13.0117 10.6702 13.8627 9.9256 14.0579 8.92234C14.1896 8.30586 14.0602 7.66253 13.7004 7.14433C13.3405 6.62614 12.7821 6.27905 12.157 6.18505C11.8912 6.14293 11.6203 6.14293 11.3544 6.18505C11.0165 6.22717 10.8053 6.10081 10.7631 5.76384C10.59 4.68518 9.79488 3.80998 8.73543 3.53201C7.97201 3.32274 7.15567 3.4399 6.48242 3.85537C5.80918 4.27083 5.33983 4.94707 5.18708 5.72172C5.18628 5.87398 5.10964 6.01588 4.98259 6.10033C4.85554 6.18479 4.69471 6.20076 4.55345 6.14293C3.93003 6.03366 3.2889 6.18296 2.7785 6.55626C2.2681 6.92955 1.9327 7.49448 1.84995 8.12023C1.69984 9.36415 2.58762 10.4946 3.83473 10.6475C3.96146 10.6686 4.09874 10.6686 4.24131 10.6686C4.38388 10.6686 4.53173 10.6686 4.67957 10.6896C4.81391 10.678 4.94605 10.7291 5.03736 10.8281C5.12866 10.927 5.16881 11.0626 5.14605 11.1951C5.12677 11.4124 4.94236 11.5779 4.72363 11.5742C4.68078 11.6169 4.55466 11.6169 4.38509 11.6169Z"
            fill="#333333"
          />
          <path
            d="M7.52722 10.1651C7.38552 10.3266 7.25352 10.4687 7.12657 10.6054C6.98861 10.7539 6.85661 10.8961 6.72461 11.0496C6.56796 11.2325 6.30613 11.2847 6.09098 11.176C5.97004 11.103 5.88965 10.9785 5.87314 10.8386C5.85662 10.6986 5.90584 10.5589 6.0065 10.4599L7.01729 9.32265C7.1229 9.21735 7.21794 9.11204 7.31299 9.00674C7.40803 8.90144 7.50308 8.79613 7.60868 8.69083C7.69554 8.55338 7.84706 8.47 8.00998 8.47C8.17291 8.47 8.32443 8.55338 8.41128 8.69083C8.88166 9.23803 9.3884 9.78523 9.86354 10.2983L9.97364 10.4172C10.0649 10.5057 10.1164 10.6272 10.1164 10.7542C10.1164 10.8811 10.0649 11.0027 9.97364 11.0911C9.8774 11.1804 9.74643 11.2226 9.61598 11.2064C9.48553 11.1901 9.369 11.1171 9.29777 11.0069C9.17631 10.8656 9.05486 10.734 8.93805 10.6074C8.8111 10.4698 8.68965 10.3382 8.57965 10.2066L8.53741 10.1645V13.0281C8.54273 13.1637 8.49103 13.2954 8.39479 13.3914C8.29855 13.4873 8.1665 13.5389 8.0305 13.5336C7.90528 13.5274 7.78836 13.4693 7.70809 13.3732C7.62783 13.2772 7.59156 13.152 7.60808 13.0281V10.2914C7.55398 10.2744 7.51991 10.2212 7.52722 10.1651Z"
            fill="#333333"
          />
        </svg>
      </el-icon>
      <span>上传文件</span>
    </slot>
  </div>
</template>
<script lang="ts" setup>
import { inject, ref } from 'vue'
import { useDisabled, useNamespace } from '@element-plus/hooks'

import { uploadContextKey } from '@element-plus/tokens'
import { throwError } from '@element-plus/utils/error'
import { uploadDraggerEmits, uploadDraggerProps } from './upload-dragger'

const COMPONENT_NAME = 'ElUploadDrag'

defineOptions({
  name: COMPONENT_NAME,
})

defineProps(uploadDraggerProps)
const emit = defineEmits(uploadDraggerEmits)

const uploaderContext = inject(uploadContextKey)
if (!uploaderContext) {
  throwError(
    COMPONENT_NAME,
    'usage: <el-upload><el-upload-dragger /></el-upload>'
  )
}

const ns = useNamespace('upload')
const dragover = ref(false)
const disabled = useDisabled()

const onDrop = (e: DragEvent) => {
  if (disabled.value) return
  dragover.value = false

  e.stopPropagation()

  const files = Array.from(e.dataTransfer!.files)
  const accept = uploaderContext.accept.value
  if (!accept) {
    emit('file', files)
    return
  }

  const filesFiltered = files.filter((file) => {
    const { type, name } = file
    const extension = name.includes('.') ? `.${name.split('.').pop()}` : ''
    const baseType = type.replace(/\/.*$/, '')
    return accept
      .split(',')
      .map((type) => type.trim())
      .filter((type) => type)
      .some((acceptedType) => {
        if (acceptedType.startsWith('.')) {
          return extension === acceptedType
        }
        if (/\/\*$/.test(acceptedType)) {
          return baseType === acceptedType.replace(/\/\*$/, '')
        }
        if (/^[^/]+\/[^/]+$/.test(acceptedType)) {
          return type === acceptedType
        }
        return false
      })
  })

  emit('file', filesFiltered)
}

const onDragover = () => {
  if (!disabled.value) dragover.value = true
}
</script>
