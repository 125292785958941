<template>
  <div
    :ref="composedDialogRef"
    :class="[
      ns.b(),
      ns.m(props.size),
      ns.is('fullscreen', fullscreen),
      ns.is('draggable', draggable),
      ns.is('align-center', alignCenter),
      { [ns.m('center')]: center, [ns.m('no-padding')]: !defaultPadding },
      customClass,
    ]"
    :style="style"
    tabindex="-1"
  >
    <header
      v-if="$slots.header && isShowHeader"
      ref="headerRef"
      :class="ns.e('header')"
    >
      <slot name="header">
        <span role="heading" :class="ns.e('title')">
          {{ title }}
        </span>
      </slot>
      <button
        v-if="showClose"
        :aria-label="cancelText"
        :class="ns.e('headerbtn')"
        type="button"
        @click="$emit('close')"
      >
        <el-icon :class="ns.e('close')">
          <component :is="closeIcon || Close" />
        </el-icon>
      </button>
    </header>
    <slot name="body-prefix" />
    <div :id="bodyId" :class="ns.e('body')">
      <slot />
    </div>
    <slot name="body-suffix" />
    <footer v-if="$slots.footer && isShowFooter" :class="ns.e('footer')">
      <slot name="footer">
        <ElButton
          v-if="cancelText"
          class="el-dialog__footerBtn"
          @click="$emit('close')"
        >
          {{ cancelText }}
        </ElButton>
        <ElButton
          :loading="loading"
          type="primary"
          class="el-dialog__footerBtn"
          @click="$emit('handleSubmit')"
        >
          {{ submitText }}
        </ElButton>
      </slot>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'
import { ElIcon } from '@element-plus/components/icon'
import { ElButton } from '@element-plus/components/button'
import { FOCUS_TRAP_INJECTION_KEY } from '@element-plus/components/focus-trap'
import { useDraggable } from '@element-plus/hooks'
import { CloseComponents, composeRefs } from '@element-plus/utils'
import { dialogInjectionKey } from '@element-plus/tokens'
import { dialogContentEmits, dialogContentProps } from './dialog-content'

const { Close } = CloseComponents

defineOptions({ name: 'ElDialogContent' })
const props = defineProps(dialogContentProps)
defineEmits(dialogContentEmits)

const { dialogRef, headerRef, bodyId, ns, style } = inject(dialogInjectionKey)!
const { focusTrapRef } = inject(FOCUS_TRAP_INJECTION_KEY)!

const composedDialogRef = composeRefs(focusTrapRef, dialogRef)

const draggable = computed(() => props.draggable)

if (draggable.value) {
  if (props.dragEl) {
    useDraggable(dialogRef, props.dragEl, draggable, props.topOffest)
  } else {
    useDraggable(dialogRef, headerRef, draggable, props.topOffest)
  }
}
</script>
