<template>
  <img src="https://js.dingdandao.com/library/cdnImage/common/empty.png" />
</template>

<script lang="ts" setup>
import { useId, useNamespace } from '@element-plus/hooks'

defineOptions({
  name: 'ImgEmpty',
})

const ns = useNamespace('empty')
const id = useId()
</script>
